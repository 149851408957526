import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/conexionApi.service';
import { Peticion } from '../services/peticion';


@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  tamano = 0;
  mensaje = "";
  img = "";
  logo = "";

  constructor(private router: Router, private https: ApiService, private cdr: ChangeDetectorRef,) { }

  t = localStorage.getItem('auth_app_token');
  ngOnInit(): void {
    
    this.obtenerDatos({ token: this.t });
    this.getMensaje({ token: this.t });
    
  }


  entrar(){
    this.router.navigate(['/pages/inicio']);
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/notificacionesUrgentes.php").subscribe(
      info => {
        this.datos = info;
        this.tamano = this.datos.length;
        this.leerNotificaciones({token: this.t, confirmacion: 'S'});
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

  getMensaje(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/bienvenida.php").subscribe(
      info => {
        this.mensaje = info.mensaje;
        this.img = info.url_img;
        this.logo = info.url_logo;
        console.log(info);
        console.log("logo "+this.logo);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

  leerNotificaciones(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/notificacionCheck.php").subscribe(
      info => {
        
      },
      err => {
        console.log(err);
      }
    );
  }

}
