import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';

@Component({
  selector: 'app-lugarinteres',
  templateUrl: './lugarinteres.component.html',
  styleUrls: ['./lugarinteres.component.scss']
})


export class LugarInteresComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  public load: boolean;
  
  constructor(private _location: Location, private https: ApiService,private route: ActivatedRoute, private cdr: ChangeDetectorRef,private router: Router ) {
    this.load = false;
  }

  id = this.route.snapshot.paramMap.get('pi');
  ngOnInit() {  
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t,tipo:'PI' });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones[this.id];
        this.cdr.markForCheck ();
        console.log(this.datos);
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }


//   verGuia(id){
//     this.router.navigateByUrl("/pages/guiavirtual/itinerario/"+id);
//   }
}