<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>

<nb-accordion>
    <nb-accordion-item>
      <nb-accordion-item-header>
        Primera duda
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        A nebula is an interstellar cloud of dust, hydrogen, helium and other ionized gases.
        Originally, nebula was a name for any diffuse astronomical object,
        including galaxies beyond the Milky Way.
      </nb-accordion-item-body>
    </nb-accordion-item>
  
    <nb-accordion-item>
      <nb-accordion-item-header>
        Segunda duda

      </nb-accordion-item-header>
      <nb-accordion-item-body>
        A nebula is an interstellar cloud of dust, hydrogen, helium and other ionized gases.
        Originally, nebula was a name for any diffuse astronomical object,
        including galaxies beyond the Milky Way.
      </nb-accordion-item-body>
    </nb-accordion-item>
  
    <nb-accordion-item disabled="true">
      <nb-accordion-item-header>
        Tercera duda
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        A nebula is an interstellar cloud of dust, hydrogen, helium and other ionized gases.
        Originally, nebula was a name for any diffuse astronomical object,
        including galaxies beyond the Milky Way.
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>