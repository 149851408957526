import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-condiciones',
  templateUrl: './condiciones.component.html',
  styleUrls: ['./condiciones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class CondicionesComponent implements OnInit {


  constructor(private _location: Location, ) {}

  ngOnInit() {  
  }

  goBack(){
    this._location.back();
  }

}