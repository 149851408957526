import { Component, Inject, OnInit } from '@angular/core';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Router } from '@angular/router';



@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styles: [`
    :host nb-layout-header ::ng-deep nav {
      justify-content: flex-end;
    }
  `],
})
export class MenuUserComponent implements OnInit {
  items = [
    { title: 'Perfil' },

    { title: 'Condiciones de uso' },
    { title: 'Política de privacidad' },
    {
      title: 'Salir',
      // HAY QUE BUSCAR COMO HACER QUE SE VEA EL ICONO (FALLO DE PACKS DE ICONOS) NO SE COMO SOLUCIONARLO
     // icon: 'log-out-outline'
    },
  ];

  user: any;
  x: any;
  nombreCompleto:any;
  constructor(private nbMenuService: NbMenuService, @Inject(NB_WINDOW) private window, private authService: NbAuthService, private router: Router) {

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
          this.nombreCompleto = this.user.data.nombre+" "+this.user.data.apellidos;
        }
      });

  }


  ngOnInit() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => this.opcion(title));
  }

  opcion(t) {
    if (t == "Salir") {
      this.router.navigate(['/auth/logout']);
    } 
    if (t == "Perfil") {
      this.router.navigate(['/pages/perfil']);
    }
    if (t == "Condiciones de uso") {
      window.open("https://cisnea.com/blog/aviso-legal/", "_blank");
      // this.router.navigate(['/pages/condiciones']);
    }
    if (t == "Política de privacidad") {
      window.open("https://cisnea.com/politicapv/", "_blank");
      // this.router.navigate(['/pages/politicas']);
    }
  }
  esconder() {
    if (window.innerWidth > 550) {
      return false;
    } else {
      return true;
    }
  }
}
