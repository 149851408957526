import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ApiService } from '../../../services/conexionApi.service';
import QrScanner from 'qr-scanner';

QrScanner.WORKER_PATH = './assets/js/qr-scanner-worker.min.js';

@Component({
  selector: 'app-escaneo-qr',
  templateUrl: './escaneo-qr.component.html',
  styleUrls: ['./escaneo-qr.component.scss']
})
export class EscaneoQrComponent implements OnInit, OnDestroy {

  user: any;
  quienSoy: string;
  public load: boolean;
  scanning: boolean = false;
  confirmResult: boolean = false;
  qrScanner;
  videoElem;
  cameraList;
  scanRegion;
  scanResult: any;
  showModal: boolean;

  constructor(private cdr: ChangeDetectorRef,private _location: Location,private authService: NbAuthService,private zone: NgZone, private swPush: SwPush, private https: ApiService,private router: Router ) {
    this.load = false;
    this.scanning = false;
    // console.log(this.videoElem)
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax;    
        this.load = true;
        if(this.quienSoy != "guia"){
            this.zone.runOutsideAngular(() => {
                this.router.navigate(['pages/error']);
              });
        }
      }
    }); 
               
  }

  ngOnInit(): void {
    this.load = false;
    this.videoElem  = document.querySelector('video');
    setTimeout(() => {
      this.scanQr();
      this.cdr.markForCheck();
    }, 1000);


  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.stopScan();
  }


  scanQr(){
      if(QrScanner.hasCamera()){ 
          this.load = true;
          this.qrScanner = new QrScanner(this.videoElem, (result) => {
  
            setTimeout( () => {
              this.scanResult = result;
              this.showModal = true;
              this.confirmResult = true; 
              console.log(this.scanResult);
              return; //Se puede implementar que mande una peticion a la base de datos, actualizando la tabla con la lectura del ticket mediante el QR.
  
            }, 750)
  
          }, 
          (error) => {
          },
          undefined, 'environment'); 
           this.qrScanner.start();
     }
    
  }

  closeModal(){
      this.showModal = false;
  }

  stopScan(){
    this.scanning = false;
    this.qrScanner.stop();
    this.qrScanner.destroy();
    this.qrScanner = null;
  }

   goBack(){
    this.load = false;
    this._location.back();
  }


}
