import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-generarticket',
  templateUrl: './generarticket.component.html',
  styleUrls: ['./generarticket.component.scss']
})
export class GenerarTicketComponent implements OnInit  {

  public load: boolean;

  constructor(private cdr: ChangeDetectorRef,private router: Router, private _location: Location) {
    this.load = false;

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }


  goBack(){
    this.load = false;
    this._location.back();
  }
 

}