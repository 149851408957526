<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
  </a>
  <div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Chats</h6>
  </div>
  <div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
    <section class="page-section mt-1 pt-1 mb-3" id="services" >
      <div class="container-fluid">
  
    <div class="row mt-5"> 
      
      <ng-container *ngIf="soyGuia == true; else elseTemplate">
        <ng-container  *ngFor="let chat of chats">
          <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
            <!-- hau que enviar el id_localizador y el pax -->
              <div class="card shadow-sm py-2 btn "  [routerLink]="['michat/g-'+chat.id+'-'+chat.pax+'-'+chat.nombre]">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2 d-flex justify-content-start">
                      <p class="text-dark nombre">{{chat.nombre}}</p>
                      
                    </div>
                    <div class="col-auto rounded-circle bg-info">
                      <i class="fas fa-comments text-white"></i>
                    </div>
                  </div> 
                  <div>
                    <p class="text-info text-left localizador">{{chat.localizador+"-"+chat.pax}}</p>
                  </div>
                </div>
              </div>
            </div> 
      </ng-container>
      

      </ng-container>


      <ng-template #elseTemplate>
            <ng-container *ngIf="atulado == true">
              <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
                  <div class="card shadow-sm py-2 btn "  [routerLink]="['michat/1']">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2 d-flex justify-content-start">
                          <p class="text-dark">Siempre a tu lado</p>
                        </div>
                        <div class="col-auto rounded-circle bg-info">
                          <i class="fas fa-comments text-white"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
          </ng-container>
          

      
          <ng-container *ngIf="resolvemos == true">

          <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
              <div class="card shadow-sm py-2 btn"  [routerLink]="['michat/2']">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                  <div class="col mr-2 d-flex justify-content-start">
                      <p class="text-dark ">Resolvemos tus dudas</p>
                  </div>
                  <div class="col-auto rounded-circle bg-info"> 
                      <i class="fas fa-question text-white"></i>
                  </div>
                  </div>
              </div>
              </div>
          </div>
        </ng-container>


        <ng-container *ngIf="contactaguia == true">
          <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
              <div class="card shadow-sm py-2 btn "  [routerLink]="['michat/3']">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2 d-flex justify-content-start">
                      <p class="text-dark">Contacto con tu guía</p>
                    </div>
                    <div class="col-auto rounded-circle bg-info">
                      <!-- <i class="fas fa-comments text-white"></i> -->
                      <span class="material-icons-outlined text-white">
                          connect_without_contact
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
    
      </ng-template>
      
        
      
        
     
      
      
  
     </div>
  </div>
  </section> 