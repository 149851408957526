import { ChangeDetectorRef, Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { Router, ActivatedRoute } from '@angular/router';

import AgoraRTC, {IAgoraRTCClient, IAgoraRTCRemoteUser, ILocalAudioTrack} from "agora-rtc-sdk-ng";
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { Peticion } from 'src/app/componentes/services/peticion';

@Component({
  selector: 'app-whisper',
  templateUrl: './whisper.component.html',
  styleUrls: ['./whisper.component.scss']
})
export class WhisperComponent implements OnInit, OnDestroy {

  user: any;
  token: any;
  public load: boolean = false;

  micStatus = false;
  volumeStatus = true;

  options = {
    // Pass your App ID here.
    appId: "c2b6f5a1e7444f82b4f4c40a6a41aa3e",
    // Pass your temp token here.
    token: null
};

  red = 'rgb(240, 77, 77)';
  green = 'rgb(94, 172, 94)' 
  greenLight = 'rgb(118, 219, 118)' 
  borderColor = '4px solid rgb(118, 219, 118)'
  client: IAgoraRTCClient;
  localAudioTrack: ILocalAudioTrack = null; 
  pax: any;
  callStatus: string;
  localStatus: boolean = false;
  remoteUsers: IAgoraRTCRemoteUser[] = [];
  remoteUsersObj = [];
  canal: string;


  constructor(private cdr: ChangeDetectorRef,private _location: Location, private zone: NgZone, protected service: NbAuthService, private router: Router, private route: ActivatedRoute, private https: ApiService) {
    this.load = false;
    this.canal = this.route.snapshot.paramMap.get('id')

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.pax = this.user.data.pax;
        this.token = token.toString();
        if (this.pax == 'hotel' || this.pax == 'cliente_hotel') {
          this.zone.runOutsideAngular(() => {
            this.router.navigate(['pages/error']);
          });
        }
      }
    });
  }

   ngOnInit() {
    setTimeout( () => {
      this.cdr.markForCheck ();
      //Nada más cargar el componente, inicia la llamada.
      this.obtenerToken(this.canal);
      
    }, 500);
    
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.leaveCall();
  }

  //Este método obtiene el token generado para el canal dado, e inicia la llamada una vez lo tiene.
  
obtenerToken(canal: string) {
  this.https.postRespuesta(canal, "https://api.cisnea.com/agoraToken/RtcTokenGenerator.php").subscribe(res => {
    this.options.token = res;
    this.startCall().then(() => {
      //Se rellena un array con los usuarios remotos, los que están conectados al mismo canal.
      this.remoteUsers =  this.client.remoteUsers;
    
      //Luego, se le da una estructura más adecuada en otro array de objetos.
      if(this.remoteUsers.length > 0){
        this.remoteUsers.forEach(userObj => {
          this.remoteUsersObj.push({user: userObj, micRemoteStatus: false, isSpeaking: false});
        })
      }
  
  
      this.load = true;
      this.callStatus = 'CONNECTED'
    });
  })
  }

  //Este método crea el cliente, que es sobre el que se va a ejecutar la llamada. Pide el permiso de acceso al micrófono y crea la pista de audio, para publicarla al SDK de Agora y una vez publicado, añade listeners para los eventos, tales como cuando un usuario ha sido publicado, un usuario ha salido, un usuario se ha unido, etc.

  async startCall(){
    this.client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});
    const uid = await this.client.join(this.options.appId, this.canal, this.options.token);
    this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.localAudioTrack.setEnabled(false);
    this.client.enableAudioVolumeIndicator();
    await this.client.publish([this.localAudioTrack]);

    //hacemos update en BD para guardar el uid del cliente
    this.updateUID({token:this.token,canal:this.canal,uid:uid});

    this.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await this.client.subscribe(user, mediaType);
      console.log("subscribe success");
      // If the subscribed track is audio.

      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track.
        remoteAudioTrack.play();
        // Se recorre el array de usuarios remotos y se establece el estado del micrófono (true o false)
        for (let i = 0; i < this.remoteUsersObj.length; i++) {
          if(this.remoteUsersObj[i].user === user){
            this.remoteUsersObj[i].micRemoteStatus = user.audioTrack.isPlaying;
            this.remoteUsersObj[i].isSpeaking = true;
          }
          
        }
        console.log(this.client.getRTCStats);
      }

    });

    //Si un usuario sale de la llamada de voz, se recorre el array y se elimina el usuario que ha salido.
    this.client.on("user-left", user => {
      for (let i = 0; i < this.remoteUsersObj.length; i++) {
        if(this.remoteUsersObj[i].user === user){
          this.remoteUsersObj.splice(i, 1);
        }
      }
 
    })

    //Cuando un usuario retira una pista de audio, mediante this.localAudioTrack.setEnabled(false), se llama a este evento y cambia el estado del micrófono de ese usuario.
    this.client.on("user-unpublished", async (user) => {
      for (let i = 0; i < this.remoteUsersObj.length; i++) {
        if(this.remoteUsersObj[i].user === user){
          this.remoteUsersObj[i].micRemoteStatus = false;
          this.remoteUsersObj[i].isSpeaking = false;
        }
        
      }
      await this.client.unsubscribe(user, "audio");

    })

    //Evento que detecta cada 2 segundos el volumen de cada pista de audio publicada. Si el volumen detectado a 5, el usuario con ese volumen se detecta como "Hablando".

    this.client.on("volume-indicator", volumes => {
      volumes.forEach((volume) => {
        console.log(`UID ${volume.uid} Level ${volume.level}`);

        if(this.client.uid == volume.uid && volume.level > 5) {
          this.localStatus = true;
        } else if(this.client.uid == volume.uid && volume.level < 5) {
          this.localStatus = false;
        }

        for (let i = 0; i < this.remoteUsersObj.length; i++) {
          this.remoteUsersObj[i].isSpeaking = false;
          if(this.remoteUsersObj[i].user.uid == volume.uid && volume.level > 5 && this.remoteUsersObj[i].user.audioTrack.isPlaying){
            this.remoteUsersObj[i].isSpeaking = true;
            console.log(this.remoteUsersObj[i].isSpeaking)
          }else if (this.remoteUsersObj[i].user.uid == volume.uid && volume.level < 5 && this.remoteUsersObj[i].user.audioTrack.isPlaying){
            this.remoteUsersObj[i].isSpeaking = false;
            console.log(this.remoteUsersObj[i].isSpeaking)
          }
          
        }
      })


    })
    // Cuando un usuario se une, llama a este evento, que inserta el usuario que se ha unido al array de usuarios remotos.

      this.client.on("user-joined", user => {
          this.remoteUsersObj.push({user: user, micRemoteStatus: false, isSpeaking: false});
      })

  }

  //Cambia entre un estado de micrófono u otro, llamando al evento user-published o user-unpublish, dependiendo de si se activa o se desactiva el micrófono.
  changeMicStatus(){
    if(!this.micStatus){
      this.micStatus = true;
      this.localStatus = false;
       this.localAudioTrack.setEnabled(true)


    }else{
      this.micStatus = false;
      this.localStatus = false;
       this.localAudioTrack.setEnabled(false)

    }
  }

  //Recorre el array de usuarios remotos y si el usuario que se está recorriendo tiene audio y el volumen está desactivado, lo activa y lo desactiva si está activado.
  changeVolumeStatus(){

      for (let i = 0; i < this.remoteUsersObj.length; i++) {
        if(this.remoteUsersObj[i].user.hasAudio){
          if(!this.volumeStatus){
            this.remoteUsersObj[i].user.audioTrack.setVolume(100);
            this.volumeStatus = true;
          }else{
            this.remoteUsersObj[i].user.audioTrack.setVolume(0);
            this.volumeStatus = false;
          }
        }else{
        }

      }
  }

  //Cierra la grabación y saca al cliente de la llamada, volviendo al componente de whispers.
  async leaveCall() { 
    // Destroy the local audio track.
    this.localAudioTrack.stop();
    this.localAudioTrack.close();
    // Traverse all remote users.
    this.client.remoteUsers.forEach( user => {
      // Destroy the dynamically created DIV container.
      const playerContainer = document.getElementById(user.uid.toString());
      playerContainer && playerContainer.remove();
    });
  
    // Leave the channel.

    await this.client.leave();
  }


  goBack() {
    this.load = false;
    this._location.back();
  }

  updateUID(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/actualizarUID.php").subscribe(
      info => {
      },
      err => {
          console.log(err);
      }
      );
  }
}
