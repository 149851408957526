import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-excursiones',
  templateUrl: './excursiones.component.html',
  styleUrls: ['./excursiones.component.scss']
})


export class ExcursionesComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  public load: boolean;
  tipo = this.route.snapshot.paramMap.get('tipo');
  inicialTipo = "E";
  titulo = "Excursiones";

  
  constructor(private route: ActivatedRoute,private _location: Location, private https: ApiService, private cdr: ChangeDetectorRef,private router: Router) {
    if (this.tipo=="excursiones") {
      this.titulo = "Excursiones";
      this.inicialTipo = "E";
    } else {
      this.titulo = "Entradas y espectáculos"
      this.inicialTipo= "EYE";
    }
    this.load = false;

  }

  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t, tipo:this.inicialTipo });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones;
        this.cdr.markForCheck ();
        console.log(this.datos);
        localStorage.removeItem('excursiones_contratadas');
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }

  verExcursion(id){

    // this.router.navigateByUrl("/pages/excursiones/id/"+id);
    this.router.navigate(['id/'+id], {relativeTo: this.route});
  }

}