<a class="history-back" (click)="goBack()">
  <i
    class="fas fa-arrow-left text-primary"
    style="font-size: 25px !important"
  ></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2">Pago</h6>
</div>
<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="!load"
>
  <div
    class="spinner-grow text-primary"
    style="width: 5rem; height: 5rem"
    role="status"
  >
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6 col-md-12 mb-3">
        <div class="card bg-primary">
          <div class="card-body preciototal">
            <div
              class="row no-gutters d-flex flex-row justify-content-center align-items-center"
            >
              <h6 class="card-title text-light text-center">
                Total: {{ totales }}€
              </h6>
            </div>
          </div>
        </div>
      </div>

      <p class="text text-primary">Forma de pago</p>

      <!-- Nuevo código con acordeon-->
      <div class="col-xl-3 mb-3" (click)="toggleTarjeta()" *ngIf="!checkedEfectivo">
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header>
              Pago con tarjeta
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <form role="form" id="paycometPaymentForm" [formGroup]="creditCardForm">
                <input type="hidden" name="amount" value="1234" />
                <input
                  type="hidden"
                  data-paycomet="jetID"
                  value="[customer-jetID]"
                />
                <div class="form-group">
                  <label for="username">Nombre de la tarjeta</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-user"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      data-paycomet="cardHolderName"
                      formControlName="name"
                      placeholder=""
                      required=""
                    />
                  </div>
                  <!-- input-group.// -->
                </div>
                <!-- form-group.// -->

                <div class="form-group">
                  <label for="cardNumber">Número de la tarjeta</label>
                  <div class="input-group" style="width: 100%;">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-credit-card"></i
                      ></span>
                    </div>
                    <div
                      id="paycomet-pan"
                    ></div>
                    <input
                      paycomet-style=""
                      paycomet-name="pan"
                      paycomet-placeholder="Introduce tu tarjeta..."
                      class="form-control"
                      type="number"
                      formControlName="number"
                    />
                  </div>
                  <!-- input-group.// -->
                </div>
                <!-- form-group.// -->

                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-group">
                      <label
                        ><span class="hidden-xs">Fecha de expiración</span>
                      </label>
                      <div class="d-flex align-items-center">
                        <input
                          class="form-control "
                          style="width: 45%; overflow-y:scroll;"
                          data-paycomet="dateMonth"
                          placeholder="Mes"
                          type="text"
                          maxlength="2"
                          formControlName="expirationMonth"
                          size=2
                        />
  
                        <span style="width: 10%; text-align: center"> / </span>
                        <input
                          class="form-control"
                          style="width: 45%"
                          data-paycomet="dateYear"
                          type="text"
                          maxlength="2"
                          formControlName="expirationYear"
                          placeholder="Año"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label
                        data-toggle="tooltip"
                        title=""
                        data-original-title="3 digits code on back side of the card"
                        >CVV</label
                      >
                      <input
                      type="text"
                        paycomet-name="cvc2"
                        paycomet-style="border:0px; width: 100%; height: 21px; font-size:12px; padding-left:7px; padding-tap:8px;"
                        paycomet-placeholder="CVV2"
                        class="form-control"
                        formControlName="cvv"
                        maxlength="3"
                        required=""
                      />
                    </div>
                    <!-- form-group.// -->
                  </div>
                </div>
                <!-- row.// -->
              </form>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>

      <ng-container *ngIf="!checkedTarjeta">
        <div class="col-xl-3 mb-3">
          <div class="card btn">
            <div
              class="card-body flex-column d-flex align-items-start justify-content-center preciototal"
            >
              <nb-checkbox (checkedChange)="toggleEfectivo()"
                >Pago en efectivo</nb-checkbox
              >
            </div>
          </div>
        </div>
      </ng-container>

      <!-- <div class="col-xl-3 mb-3">
              <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>
                Pago en efectivo
              </nb-accordion-item-header>
              <nb-accordion-item-body>

              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
    </div> -->

      <!-- <ng-container>
          <div class="col-xl-3 mb-3">
            <div class="card btn ">
              <div class="card-body flex-column d-flex align-items-start justify-content-center preciototal">
                  <nb-checkbox (checkedChange)="toggleTarjeta($event)">Pago con tarjeta</nb-checkbox>
              </div>
            </div>
          </div>
        </ng-container> -->

      <!-- <p class='text text-primary'>Recepción de tickets</p>
        <div class="col-xl-3 mb-3">
          <div class="card  btn ">
            <div class="card-body flex-column d-flex align-items-start justify-content-center preciototal">
                <nb-checkbox (checkedChange)="toggleEmail($event)">Email</nb-checkbox>
            </div>
          </div>
        </div> -->

      <div class="d-flex justify-content-center align-items-center w-100 m-3">
        <button
          type="button"
          class="btn btn-success text-center w-100"
          (click)="goTerminar()"
        >
          Pagar
        </button>
      </div>
    </div>
  </div>
  <script src="https://api.paycomet.com/gateway/paycomet.jetiframe.js"></script>
</section>
