<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta">
    <span>Pago</span>
  </div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
    <div class="container-fluid">
  
      <div class="row">
          

        <div class="col-xl-3 col-md-6 mb-3">
          <div class="card bg-primary" >
            <div class="card-body preciototal">
              <div class="row no-gutters d-flex flex-row justify-content-center align-items-center">
                <h6 class="card-title text-light text-center">Total: {{total}}€</h6>
              </div>
            </div>
          </div>
        </div>
  
        <p class='text text-primary'>Forma de pago</p>
        <div class="col-xl-3 mb-3">
          <div class="card btn ">
            <div class="card-body flex-column d-flex align-items-start justify-content-center preciototal">
                <nb-checkbox (checkedChange)="toggleTarjeta($event)">Pago con tarjeta</nb-checkbox>
            </div>
          </div>
        </div>
 
        <!-- <p class='text text-primary'>Recepción de tickets</p>
        <div class="col-xl-3 mb-3">
          <div class="card  btn ">
            <div class="card-body flex-column d-flex align-items-start justify-content-center preciototal">
                <nb-checkbox (checkedChange)="toggleEmail($event)">Email</nb-checkbox>
            </div>
          </div>
        </div> -->
  
  
        <div class='d-flex justify-content-center align-items-center w-100 m-3'>
          <button type="button" class="btn btn-success text-center w-100 "  (click)="goTerminar()" >Pagar</button>
        </div>
  
  
      </div>
    </div>
  </section>