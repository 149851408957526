<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
  <div class="text-center contenedor-ruta align-items-center mb-4">
    <h6 class="text-primary mt-2 ">Lector QR</h6>
  </div>
  <div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>


  <div class="row">
    
    <div class="col d-flex flex-column subirArchivo">

      <button class="btn btn-primary" (click)="goToCall()"> ESCANEAR CODIGO QR </button>

    </div>
  </div>
