<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta">
  <!-- <h6 class="text-primary mt-2 ">Entrada para {{datos.nombre}}</h6> -->
  <h6 class="text-primary mt-2 ">Entrada para Sealife Benalmádena</h6>
</div>

<section class="page-section mt-5 pt-1 mb-3" id="services">
  <div class="container-fluid">

    <div class="row">

      <div class="col-xl-3 col-md-6 mb-3">
        <div class="card py-2 btn">
          <div class="card-body">
            <div class="row no-gutters d-flex flex-row justify-content-between align-items-center">
              <div class="col">
                <h6>Duración</h6>
                <h6>Precio</h6>
                
              </div>
              <div class="col">
                  <h6 class="mb-3 mt-2 text-primary" ng-i>Dia completo</h6>
                  <h6 class="mb-3 text-primary">Desde 14€</h6>
              </div>
              <!-- <ng-container *ngIf="datos.dias == 'C'; else elseTemplate"> -->
                <!-- <p class="card-title  text-primary" ng-i>Dia completo</p> -->
              <!-- </ng-container> -->
              <!-- <ng-template #elseTemplate> -->
                <!-- <p class="card-title  text-primary" ng-i>Medio dia</p> -->
              <!-- </ng-template> -->
              <!-- <ng-container *ngIf="datos.precio != null; else elsePrecio"> -->
                <!-- <p class="card-title  text-primary">Desde 14€</p> -->
              <!-- </ng-container> -->
              <!-- <ng-template #elsePrecio>
                <p></p>
              </ng-template> -->
            </div>
          </div>
        </div>
      </div>



      
      <div class="col-xl-3 mb-3">
        <div class="card  btn ">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <!-- <ng-container *ngIf="datos.hora_recogida != null; else elseHora"> -->
              <p class='text'>Próximas fechas disponibles</p>
            <!-- </ng-container>
            <ng-template #elseHora>
              <p class='text'>Próximas fechas disponibles, con salida desde tu hotel {{datos.hotel}}</p>
            </ng-template> -->
              
              
                  <nb-calendar class="w-100 calendario" [(date)]="date" [min]="min" [max]="max"></nb-calendar>
                  <h6 class="text-center mt-2 mb-2">Fecha: <span class="text-primary">{{ date | date }}</span> </h6>
             
          </div>
        </div>
      </div>

     
      
      <p class='text text-primary'>Información de la actividad</p>
      <div class="col-xl-3 mb-3">
        <div class="card  btn ">
          <div class="card-body flex-column d-flex align-items-start justify-content-center">
            <p class='text text-left' >Se puede reservar de jueves - domingo</p>
            <p class='text text-left' >Accesible con silla de ruedas</p>
            <p class='text text-left' >Horario: 11:00 - 19:00</p>
          </div>
        </div>
      </div>

      <!-- <p class='text text-primary'>Suplementos</p>
      <div class="col-xl-3 mb-3">
        <div class="card py-2 btn ">
          <div class="card-body estrecho d-flex justify-content-between align-items-center">
            <ng-container *ngIf="datos.almuerzo=='O'; else elseAlmuerzo">
              <nb-checkbox (checkedChange)="toggle($event)">Añadir Almuerzo/Comida</nb-checkbox>
            </ng-container>
            <ng-template #elseAlmuerzo>
              <ng-container *ngIf="datos.almuerzo=='I'; else elseNoIncAl">
                <nb-checkbox (checkedChange)="toggle($event)" checked>Añadir Almuerzo/Comida</nb-checkbox>
              </ng-container>
              <ng-template #elseNoIncAl>
                <p></p>
              </ng-template>
              
            </ng-template>
            
            
            <p class='text-primary'>{{datos.precioS}}€</p>
          </div>
        </div>
      </div> -->

      <p class='text text-primary'>Precios</p>
      <div class="col-xl-3 mb-3">
        <div class="card py-2 btn ">
          <div class="card-body estrecho">
            <div class="row mr-1 ml-1 d-flex justify-content-between align-items-center">
              <p class='text-dark'>Niños (3-9 años)</p>
              <p class='text-primary'>13,95€</p>
            </div>
            <div class="row mr-1 ml-1 d-flex justify-content-between align-items-center">
              <p class='text-dark'>General</p>
              <p class='text-primary'>16,95€</p> 
            </div>
          </div>
        </div>
      </div>


      <ng-container *ngFor="let item of personas; let idx = index">

        <div class="col-xl-4  mb-3">
          <div class="card py-2 btn">
            <div class="card-body estrecho">
              <nb-toggle (change)="comprobar(idx+1,item.id);" labelPosition="start">Contratar para esta persona</nb-toggle>
              <input class="mt-1" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Nombre" value="{{item.nombre}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Apellidos" value="{{item.apellidos}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="DNI" value="{{item.dni}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Edad" value="{{item.edad}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Habitación" value="{{item.hab}}">
            </div>
          </div>
        </div>

      </ng-container>

       <div class="col-xl-4  mb-3" *ngIf="clickeable == true">
          <div class="card py-2 btn">
            <div class="card-body estrecho">
              <input class="mt-1" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Nombre" [(ngModel)]="nomNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Apellidos" [(ngModel)]="apellidosNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="DNI" [(ngModel)]="dniNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Edad" [(ngModel)]="edadNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Habitación" [(ngModel)]="habitacionNewUser">
              <button type="button" class="btn btn-info text-center text-white mt-2 w-100" (click)="addUser();">Añadir usuario a la excursion</button>
            </div>
          </div>
        </div> 

           
      <div class="col-xl-4  mb-3">
        <div class="card py-2 btn" (click)="isClick();">
          <div class="card-body estrecho">
            <p>AÑADIR PERSONA</p>
          </div>
        </div>
      </div>
              

      <div class='d-flex justify-content-center align-items-center w-100'>
        <button type="button" class="btn btn-success text-center w-100" (click)="enviarDatos();" >Comprar</button>
      </div>


    </div>
  </div>
</section>