<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>

<div class="text-center contenedor-ruta align-items-center mb-4">
  <h6 class="text-primary mt-2">Lector QR</h6>
</div>

<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div
    class="spinner-grow text-primary"
    style="width: 5rem; height: 5rem"
    role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>

<div class="row">
  <div class="col d-flex flex-column scanQr">
    <video>
    </video>

<div class="backgroundModal" *ngIf="showModal">
  <div class="modalScan" *ngIf="confirmResult" >
    <div class="modalWrapper">
      <div class="row paddRow ">
        <div class="col align-items-center justify-content-center">
          <i class="fas fa-check-circle"></i>
          <h5 class="m-4">Ticket confirmado</h5>
          <button class="btn btn-success" (click)="closeModal()"> Cerrar </button>
        </div>
      </div>


    </div>
  </div>

  <!-- Modal en caso de error por parte de la base de datos o backend. No implementado. -->

  <!-- <div class="modalScan">
    <div class="modalWrapper">
      <div class="row paddRow ">
        <div class="col align-items-center justify-content-center">
          <i class="fas fa-times"></i>
          <h5 class="m-4">Ha ocurrido un error, prueba de nuevo.</h5>
          <button class="btn btn-danger" (click)="closeModal()"> Cerrar </button>
        </div>
      </div>


    </div>
  </div> -->

</div>

  </div>
</div>
