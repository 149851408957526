import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import * as mapboxgl from 'mapbox-gl';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-itinerario-guiado',
  templateUrl: './itinerario-guiado.component.html',
  styleUrls: ['./itinerario-guiado.component.scss']
})
export class ItinerarioGuiadoComponent implements OnInit {
  public load: boolean;

  baseMapboxUrl: string = "https://api.mapbox.com/directions/v5/mapbox/walking/";

  status: boolean;
  t:string;
  datos:any;
  id:any;
  idItinerario:number;
  nombreCiudad:string;
  pais:string;
  map: any;
  marcadores: mapboxgl.Marker[] = [];
  paradas:any = [];
  started = false; 
  audios = [];
  indexSonando = -1;
  iconAudio: HTMLElement;
  geolocate: mapboxgl.GeolocateControl;
  rutas:any = [];


  constructor(private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private renderer: Renderer2) { 
    this.load = false;
    this.t = localStorage.getItem("auth_app_token");
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('it');
    this.obtenerDatos({token:this.t});
    this.buildMap();

    setTimeout(() => {
      this.load = true;
        this.cdr.markForCheck ();
      }, 500);
      
  }

  //Construye el mapa, añadiendo geolocalizacion, su centro, y los marcadores. En ese orden.

  buildMap(){
    (mapboxgl as any).accessToken = environment.mapboxKey;
    
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 15.2
    });
    this.map.addControl(
      this.geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
        showAccuracyCircle: false,
        showUserLocation: true
      })
      );

      this.marcadores.splice(0);
      
      setTimeout(() => {
        if(this.nombreCiudad === 'Sevilla'){
          this.map.setCenter([-5.983783166189114, 37.38779599776547]);
        var marker = new mapboxgl.Marker().setLngLat([-5.986953262142689, 37.37731829754417]).addTo(this.map);
        var marker2 = new mapboxgl.Marker({color: '#FF0000'}).setLngLat([-5.993153539860706, 37.385785500968346]).addTo(this.map);
        var marker3 = new mapboxgl.Marker({color: '#9542f5'}).setLngLat([-5.987215529402087, 37.389953308808536]).addTo(this.map);
        this.marcadores.push(marker, marker2, marker3);
        console.log(this.marcadores);
      }else if(this.idItinerario == 1){
        this.map.setCenter([-4.418492946226186, 36.720617979968594]);

        this.paradas.forEach(element => {
          const lng:number = parseFloat(element.longitud);
          const lat:number = parseFloat(element.latitud);
          const marker = new mapboxgl.Marker({color: '#539bf0'}).setLngLat([lng, lat]).addTo(this.map);
          this.marcadores.push(marker);
        });
      }
    }, 300);

  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  linearMode = true;

  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }

  obtenerDatos( body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/guiavirtual.php").subscribe(
      info => {
        this.datos = info[this.id];
        this.paradas = this.datos.paradas;
        this.idItinerario = this.datos.id;
        this.nombreCiudad = this.datos.nombre;
        this.pais = this.datos.pais;
        this.paradas.forEach(element => {
          var miaudio = new Audio();
          miaudio.src = element.audio;
          this.audios.push(miaudio)
        });       
        this.cdr.markForCheck ();
        console.log(this.paradas);
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );

  }
  // @ViewChild('audioOption') audioPlayerRef: ElementRef;

  startAudio(index:number){
    if (index != this.indexSonando && this.indexSonando != -1) {
      this.audios[this.indexSonando].pause();
      this.changeAudioIcon(this.indexSonando);
      this.started = false;
      this.indexSonando = index;
    }
    if (this.started == false) {
      console.log("play");
      this.started = true;
      this.manejarMarcadores(index);
      this.audios[index].load();
      this.audios[index].play();
      this.changeAudioIcon(index);
      if( index >= 1){
        this.obtenerRuta(this.paradas[index - 1].longitud, this.paradas[index - 1].latitud, this.paradas[index].longitud, this.paradas[index].latitud);
      }
      this.volarAPunto();
      if (this.indexSonando == -1) {
        this.indexSonando = index;
      }
    }else{
      this.audios[index].pause();

      this.started = false;
      this.changeAudioIcon(index);

    }
    this.cdr.markForCheck();
  }

  //Si hay marcadores, los elimina del mapa. Si no, limpia el array de marcadores y añade un marcador dadas unas coordenadas.

  manejarMarcadores(index: number){
    if(this.marcadores.length > 1){
      this.marcadores.forEach((marker) => {
        marker.remove();
      })
    }
    this.marcadores.splice(0);
    let marker = new mapboxgl.Marker({color: '#539bf0'}).setLngLat([this.paradas[index].longitud, this.paradas[index].latitud]).addTo(this.map);
    this.marcadores.push(marker);
  }

  //Vuela al punto dado unas coordenadas.
  volarAPunto(){
    this.map.flyTo({
      center: this.marcadores[0].getLngLat(),
      zoom: 16.4,
      curve: 2,
      speed: 0.5
    })
  }

  //Obtiene la ruta entre dos paradas y la añade al mapa.
  obtenerRuta(lngOrigen, latOrigen, lngDestino, latDestino){
    this.https.getRuta(`${this.baseMapboxUrl}${lngOrigen},${latOrigen};${lngDestino},${latDestino}?geometries=geojson&access_token=${environment.mapboxKey}`).subscribe( (ruta:any) => {
      this.rutas = ruta.routes[0].geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: this.rutas
        }
      }
      //Si ya tiene una "capa" ruta, la reemplaza
      if(this.map.getSource('route')){
        this.map.getSource('route').setData(geojson);
      }else {
        //Si no la tiene, añade una ruta
        this.map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson

          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#539bf0',
            'line-width': 5,
            'line-opacity': 0.75
          }
        })
      }
    })
  }


  //Cambia el estado del icono de cada parada dependiendo si está sonando o no.

  changeAudioIcon(index: number){ // Toma el icono al que se hace click
    this.iconAudio = document.getElementById(index.toString());
    if(this.started == true && this.iconAudio.classList.contains("fa-play")){ //Si el audio está sonando y el icono es "play", quita el icono play y añade el pause
      this.renderer.removeClass(this.iconAudio, "fa-play");
      this.renderer.addClass(this.iconAudio, "fa-pause");
    }else{            
      //Lo mismo, si el audio no está sonando, quita el icono pause y pone el icono play.                           
      this.renderer.removeClass(this.iconAudio, "fa-pause");
      this.renderer.addClass(this.iconAudio, "fa-play");

    }

  }
}
