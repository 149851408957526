import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-generarticketR',
  templateUrl: './generarticketR.component.html',
  styleUrls: ['./generarticketR.component.scss']
})
export class GenerarTicketRComponent implements OnInit  {

  
  constructor(private router: Router, private _location: Location) {

  }

  ngOnInit(): void {

  }


  goBack(){
    this._location.back();
  }
 

}