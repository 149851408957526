<div class="d-flex justify-content-start">
  <img src="assets/img/Logo-Cisnea-WEB-blanco.png" alt="" class="ml-auto mr-auto p-3 w-75 img-fluid text-center">
</div> 
<p class="sub-title">Por favor, intruduce tu localizador o nombre de la reserva</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh No!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>¡Hurra!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Localizador</label>
    <input nbInput fullWidth [(ngModel)]="user.email" #email="ngModel" name="email" id="input-email" pattern="^([a-zA-Z0-9-\s]+)$"
      placeholder="ej. 183037294" fieldSize="large" autofocus
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Localizador obligatorio
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Localizador incorrecto
      </p>
    </ng-container>
    <label class="label" for="input-nombre">Nombre</label>
    <input nbInput fullWidth [(ngModel)]="user.fullname" #email="ngModel" name="fullname" id="input-fullname" pattern="^([a-zA-Z\s]+)$"
      placeholder="Introduzca su nombre" fieldSize="large" autofocus
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Nombre obligatorio
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Nombre incorrecto
      </p>
    </ng-container>
  </div>

  <!-- <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Contraseña:</label>
      <a class="forgot-password caption-2" routerLink="../request-password">¿Has olvidado la contraseña?</a>
    </span>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="Contraseña"
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Contraseña obligatoria
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        La contraseña debe contener
        entre {{ getConfigValue('forms.validation.password.minLength') }}
        y {{ getConfigValue('forms.validation.password.maxLength') }}
        caracteres
      </p>
    </ng-container>
  </div> -->

  <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Recordar</nb-checkbox>
  </div> -->

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted">
    Ingresar
  </button>
</form>

<!-- <section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  O entra con:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link" [routerLink]="socialLink.link" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url" [attr.href]="socialLink.url" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section> -->

<section class="another-action" aria-label="Register">
  ¿Tienes problemas para acceder? <br><a class="text-link" routerLink="../contactanos">Contáctanos</a>
</section>

