import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';

@Component({
  selector: 'app-recomendacion',
  templateUrl: './recomendacion.component.html',
  styleUrls: ['./recomendacion.component.scss']
})


export class RecomendacionComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  
  constructor(private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router, private route: ActivatedRoute ) {
    
  }

  id = this.route.snapshot.paramMap.get('numero');

  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    localStorage.removeItem('excursiones_contratadas');
    // this.obtenerDatos({ token: t });
  }


  goBack(){
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones[this.id];
        this.cdr.markForCheck ();
        console.log(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }

}