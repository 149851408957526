<!-- <h1 id="title" class="title">Se te olvidó tu contraseña</h1>
<p class="sub-title">
  Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>¡Oh no!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>¡Hurra!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>


<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Ingrese su dirección de correo electrónico:</label>
    <input nbInput
           [(ngModel)]="user.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="Dirección de correo electrónico"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        ¡Correo electronico requerido!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        ¡El correo electrónico debe ser verdadero!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !requestPassForm.valid"
          [class.btn-pulse]="submitted">
          Solicitud de contraseña
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Ya tengo una cuenta</a></p>
  <p><a routerLink="../register" class="text-link">Registrar</a></p>
</section> -->

<h1 id="title" class="title text-white">Contáctanos</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Uyy!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Genial!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="requestPass()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-name">Nombre completo:</label>
    <input nbInput [(ngModel)]="user.fullName" #fullName="ngModel" id="input-name" name="fullName"
      placeholder="Nombre completo" autofocus fullWidth fieldSize="large"
      [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.fullName.required')"
      [minlength]="getConfigValue('forms.validation.fullName.minLength')"
      [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
      [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null">
    <ng-container *ngIf="fullName.invalid && fullName.touched">
      <p class="caption status-danger" *ngIf="fullName.errors?.required">
        Se requiere nombre completo!
      </p>
      <p class="caption status-danger" *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength">
        El nombre completo debe contener
        {{getConfigValue('forms.validation.fullName.minLength')}}
        a {{getConfigValue('forms.validation.fullName.maxLength')}}
        caracteres
      </p>
    </ng-container>
  </div>


  <div class="form-control-group">
    <label class="label" for="input-re-password">Escribe tu mensaje</label>

    <textarea nbInput [(ngModel)]="user.confirmPassword" fieldSize="large" fullWidth placeholder="Mensaje"></textarea>
  </div>

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted">
    Enviar
  </button>
</form>

<!-- <section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  o entra con:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link" [routerLink]="socialLink.link" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url" [attr.href]="socialLink.url" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section> -->

<section class="another-action" aria-label="Sign in">
  ¿Tienes el localizador? <a class="text-link" routerLink="../login">Iniciar sesión</a>
</section>
