<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Detalle de notificación</h6>
</div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
        <span class="visually-hidden">Cargando...</span>
    </div>
</div>
  <!-- <ng-container *ngIf="pax!='guia'; else elseTemplate"> -->
<section class="page-section mt-1 pt-1 mb-3" id="services" >
    <div class="container-fluid">
        <div class="row mt-5">  

            <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
                <div class="card btn ">
                    <div class="card-body">
                        <h6>{{datos.tituloUrgente}}</h6>
                        <p class="text-light">{{datos.subtitulo}}</p>
                        <p>{{datos.texto}}</p>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let item of datos.docs; let i=index">
                <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
                    <div class="card btn ">
                        <div class="card-body ">
                          <div class="row">
                              <div class="col">
                                <i class="far fa-eye text-primary"></i>
                              </div>
                              <div class="col">
                                  <p> Ver {{item.desc}}</p>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            
        </div>
    </div>
</section> 