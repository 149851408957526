<a class='history-back' [routerLink]="['/pages/inicio']">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
  </a>
<nb-card>
    <nb-card-header>
        <nb-user [name]="nombreCompleto" [title]="localizadorCompleto"></nb-user>
    </nb-card-header>
    <nb-card-body >
        <form #usuarioForm="ngForm" novalidate>
            <div class="row">
        
                <div class="col-sm-12 col-md-6">
                    <label class="label text-primary">Nombre completo</label>
                    <p> {{user.data.nombre}} {{user.data.apellidos}}</p> 
                </div>
                <div class="col-sm-12 col-md-6">
                    <label class="label text-primary">Localizador</label>
                    <p>{{user.data.localizador}}-{{user.data.pax}}</p> 
                </div>

                <div class="col-sm-12 col-md-6 d-flex justify-content-between align-items-center">
                    <label class="label text-primary">Notificaciones push</label>
                    <nb-toggle [checked]="checked" (checkedChange)="changePushSubscription()"></nb-toggle>
                </div>
                
                <div class="col-md-12 d-flex justify-content-center w-100 mt-3">
                    <button type="submit"  class="w-100 btn btn-outline-primary text-center p-2 m-2 "  [routerLink]="[ '/auth/logout' ]" >Cerrar sesión</button>
                    <!-- <i class="fas fa-sign-out-alt text-primary cerrarsesion" style="font-size: 18px!important;"></i> -->
                </div>
                 
        

            </div>
        </form>
    </nb-card-body>
</nb-card>