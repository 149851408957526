import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { Peticion } from 'src/app/componentes/services/peticion';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-guias',
  templateUrl: './guias.component.html',
  styleUrls: ['./guias.component.scss']
})


export class GuiasComponent implements OnInit {

  public load: boolean;

  constructor(private _location: Location, private https: ApiService, private cdr: ChangeDetectorRef, private route: ActivatedRoute,private zone: NgZone,protected service: NbAuthService,private router: Router) {

    this.load = false;

   }

  ngOnInit() {
    var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t, });
    
    // this.enviarDatos();
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
  }, 500);

    
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/consultaMensajes.php").subscribe(
      info => {

        //  alert(this.tipoChat[this.tipoChat.length - 1]);

      
        
        this.cdr.markForCheck ();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }



  goBack(){
    // this.load = false;
    // this._location.back();
  }


}
