<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
<section class="page-section mb-3" id="services">
    <div class="container-fluid">
            <div class="row mb-5">
                <img src={{datos.img}} class='img-fluid rounded' />
                <!-- <img src='assets/img/bar ejemplo.jpg' class='img-fluid rounded' /> -->
                <div class="card shadow w-100 info mb-5">
                    <div class="card-body mb-3 excursion-card">
                        <h5 class="title">{{datos.nombre}}</h5>
                        <!-- <p class="text">{{datos.desc}}</p> -->
                        <div class="text" [innerHTML]="datos.desc"></div>
                           
                    </div>
                </div>
            <div class='comprar d-flex justify-content-center align-items-center'>
                <button type="button" class="btn btn-success text-center" (click)="contratar()" >Comprar</button>
            </div>
        </div>
    </div>
</section>