import { Component, ChangeDetectionStrategy} from '@angular/core';


@Component({
  selector: 'app-pages',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {

  constructor() {
  }
  
  tabs: any[] = [
    {
      title: 'Documentación',
      route: '/pages/documentacion',
      icon: 'file-alt',
      extension: 'far',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
     // queryParams: { param1: 123456, param2: 'test' }, (para pasar parametros a la url)
    },
    {
      title: 'Excursiones',
      route: '/pages/excursiones',
      icon: 'place-of-worship',
      extension: 'fas',
      responsive: true,
    },
    {
      title: 'Chat Box',
      route: '/pages/chatbox',
      icon: 'comment',
      extension: 'far',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
    },
    {
      title: 'Guía Virtual',
      route: '/pages/guiavirtual',
      icon: 'route',
      extension: 'fas',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
    },
    {
      title: 'Whisper',
      route: '/pages/whispers',
      icon: 'assistive-listening-systems',
      extension: 'fas',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
    },
  ];
}
