<a class="history-back" (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px !important"></i>
</a>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Ruta</h6>
</div>

<div class="container-fluid mt-4">
  <div class="row">
    <div id="map">
    </div>
  </div>

  <div class="row itinerarioCard">
    <div class="col guiaBackground">
      <div class="row infoGuia">
        <div class="col">
          <div class="row rowLine pb-2 pt-2">
            <div class="col-1 ">
              <i class="fas fa-map-marker-alt text-primary"></i>
            </div>
            <div class="col">
              <h6 class="titleMain">{{nombreCiudad}}</h6>
              <p class="subTitle">{{pais}}</p>
            </div>
          </div>
          <div class="row d-flex flex-row pt-2 rutaBtn">
            <div class="col">
              <div class="btnContainer d-flex justify-content-center" (click)="startAudio(0)">
                <i class="fas fa-shoe-prints"></i>
                <h6 class="pl-2">{{started ? 'En ruta' : '¡Vamos!'}}</h6>
              </div>

            </div>
          </div>
        </div>
      </div>

     

      <div class="row mt-4">
        <div class="col">
          <h2>Itinerario</h2>
          <div class="row pt-2 pb-4 rowItinerario flex-row flex-nowrap justify-content-start">
            
              <div class="col-5 col-paradas" *ngFor="let item of paradas; let in=index" (click)="startAudio(in)">


                <div class="pasoItinerario d-flex justify-content-center align-items-center">                  
                  <i #iconAudio id={{in}} class="fas fa-play text-primary"></i>

                  <!-- fa-pause -->
                </div>
                <div class="col nombreParada">
                  <p class="itinerarioSub">{{item.nombre_parada}}</p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>