import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})


export class PagoComponent implements OnInit {

  checkedTarjeta = false;
  checkedEfectivo = false;
  checkedEmail = false;
  id = this.route.snapshot.paramMap.get('numero');
  insercion = this.route.snapshot.paramMap.get('insercion');
  peticion = new Peticion();
  datos:any = [];
  infoExcUsuarios:any;
  totales = 0;
  exc_contrats:any;
  public load: boolean;
  tipo = this.route.snapshot.paramMap.get('tipo');
  inicialTipo = "E";
  user;
  quienSoy;
  mostrarEfectivo = false;
  localizador;

  creditCardForm = this.fb.group({
    name: [],
    number: [, Validators.pattern('0-9')],
    expirationMonth: [],
    expirationYear: [],
    cvv: [, Validators.maxLength(3)]

  })


  constructor(private zone: NgZone,private authService: NbAuthService,private _location: Location,private route: ActivatedRoute,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router, private fb: FormBuilder ) {
    if (this.tipo=="excursiones") {
      this.inicialTipo = "E";
    } else {
      this.inicialTipo= "EYE";
    }
    this.load = false;

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax; 
        
        if( this.quienSoy == "hotel" || this.quienSoy == "guia"){
          this.mostrarEfectivo = true;
          this.localizador = this.route.snapshot.paramMap.get('localizador');
        }
        
      }
    }); 
  }
  t = localStorage.getItem('auth_app_token');
  ngOnInit() { 
    
    this.exc_contrats = localStorage.getItem('excursiones_contratadas');
    this.obtenerDatosExcursion({ token: this.t,insercion:this.insercion,excursion:this.exc_contrats });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  toggleTarjeta() {
    this.checkedTarjeta = !this.checkedTarjeta;
  }

  toggleEfectivo() {
    this.checkedEfectivo = !this.checkedEfectivo;
  }

  toggleEmail(checked: boolean) {
    this.checkedEmail = checked;
  }

  goTerminar(){
    //insert en ticket
    if (this.checkedEfectivo) {
      this.confirmarPago({token:this.t,insercion:this.insercion,pago:"E"});
    }else{
      this.confirmarPago({token:this.t,insercion:this.insercion});
    }
    
    localStorage.removeItem("excursiones_contratadas");
    this.router.navigate(['terminar'],{relativeTo: this.route});
  }

  obtenerDatosExcursion(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/datosExcComprada.php").subscribe(
        info => {
            this.cdr.markForCheck ();
            info.forEach(element => {
            this.totales += element.total;  
            this.load = true;
            });
        },
        err => {
            console.log(err);
        }
        );
  }

  confirmarPago(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/confirmarPago.php").subscribe(
        info => {
            this.cdr.markForCheck ();
        },
        err => {
          alert(err);
        }
        );
  }

}