import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-resumenExc',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})


export class ResumenComponent implements OnInit {

    id = this.route.snapshot.paramMap.get('numero');
    insercion = this.route.snapshot.paramMap.get('insercion');
    peticion = new Peticion();
    datos:any = [];
    infoExcUsuarios:any;
    totales = 0;
    public load: boolean;
    tipo = this.route.snapshot.paramMap.get('tipo');
    inicialTipo = "E";
    ruta = "excursiones";
    user: any;
    quienSoy: string;
    localizador;


    constructor(private authService: NbAuthService,private _location: Location,  private route: ActivatedRoute,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router) {
        if (this.tipo=="excursiones") {
            this.inicialTipo = "E";
            this.ruta = "excursiones";
          } else {
            this.inicialTipo= "EYE";
            this.ruta = "entradas";
          }
        this.load = false;

        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

            if (token.isValid()) {
              this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
              this.quienSoy = this.user.data.pax;       
              if(this.quienSoy == "hotel" || this.quienSoy == "guia"){
                this.localizador = this.route.snapshot.paramMap.get('localizador');
              }
            }
        }); 

    }

    exc_contrats;
    t ;
    ngOnInit() { 
        this.t = localStorage.getItem('auth_app_token');
        this.exc_contrats = localStorage.getItem('excursiones_contratadas');
        this.obtenerDatosExcursion({ token: this.t,insercion:this.insercion,excursion:this.exc_contrats });
        if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
            this.obtenerDatos({ token: this.t, tipo:this.inicialTipo,localizador: this.localizador });
        } else {
            this.obtenerDatos({ token: this.t, tipo:this.inicialTipo });
        }
        
        setTimeout(() => {
            this.load = true;
            this.cdr.markForCheck ();
          }, 500);
    
    }

    goBack(){
        localStorage.removeItem('excursiones_contratadas');
        this.load = false;
        this._location.back();
    }

    obtenerDatosExcursion(body: Peticion){
        this.https.postRespuesta(body, "https://api.cisnea.com/datosExcComprada.php").subscribe(
            info => {
                this.infoExcUsuarios = info;
                this.cdr.markForCheck ();
                console.log(this.infoExcUsuarios);
                this.infoExcUsuarios.forEach(element => {
                this.totales += element.total;  
                });
                console.log(this.totales);
            },
            err => {
                console.log(err);
            }
            );
    }

    obtenerDatos(body: Peticion) {
        this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
        info => {
            this.datos = info.excursiones;
            this.cdr.markForCheck ();
           this.load = true;
        },
        err => {
            console.log(err);
        }
        );
    }

    pagar(){
        this.load = false;
        this.router.navigate(['pago'],{relativeTo: this.route});
    }

    contratar(indice){
        this.load = false;
         this.router.navigate(['/pages/lugares/'+this.ruta+'/id/'+indice]);
    }

}