<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">{{titulo}} a {{datos.nombre}}</h6>
</div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
  <div class="container-fluid">

    <div class="row">

      <div class="col-xl-3 col-md-12 mb-3">
        <div class="card py-2 btn">
          <div class="card-body">
            <div class="row no-gutters d-flex flex-row justify-content-between align-items-center">
              <div class="col d-flex flex-row justify-content-center align-items-center flex-column">
                <p class="font-weight-bold text-black-50" style="font-size: 16px; margin:2px 0px 2px 5px!important;">Duración</p>
                <p class="font-weight-bold text-black-50" style="font-size: 16px; margin:2px 0px 2px 5px!important;">Precio</p>
                
                
              </div>
              <div class="col d-flex justify-content-center align-items-center flex-column">
                <ng-container  *ngIf="datos.dias == 'C'; else elseTemplate">
                  <span class="d-block mb-4 text-primary" ng-i>Dia completo</span>
                </ng-container>
                <ng-template  #elseTemplate>
                  <span class="d-block text-primary" ng-i>Medio día</span>
                </ng-template>
                <ng-container *ngIf="datos.precio != null; else elsePrecio">
                  <span class="d-block text-primary">{{datos.precio}}€</span>
                </ng-container>
                <ng-template #elsePrecio>
                  <span class="d-block text-primary"> - </span>
                </ng-template>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>



      
      <div class="col-xl-3 mb-3">
        <div class="card  btn ">
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <ng-container *ngIf="inicialTipo != 'EYE'">
              <ng-container *ngIf="datos.hora_recogida != null; else elseHora">
                <p class='text'>Próximas fechas disponibles, con salida a las <mark class="text-primary"> {{datos.hora_recogida}}h </mark>  desde tu 
                <mark class="text-primary">Hotel {{datos.hotel}}.</mark></p>
              </ng-container>
              <ng-template #elseHora>
                <p class='text'>Próximas fechas disponibles, con salida desde tu <mark class="text-primary"> Hotel {{datos.hotel | titlecase}}. </mark> </p>
              </ng-template>
            </ng-container>
            

              
                  <nb-calendar class="w-100 calendario" [(date)]="date" [min]="min" [max]="max" ></nb-calendar>
                  <h6 class="text-center mt-2 mb-2 text-black-50">Fecha: <span class="text-primary">{{ date | date }}</span> </h6>
             
          </div>
        </div>
      </div>
 
     
      <ng-container *ngIf="datos.servicios != '' ">
        <p class='text text-primary'>Servicios incluidos</p>
        <div class="col-xl-3 mb-3">
          <div class="card  btn ">
            <div class="card-body flex-column d-flex align-items-start justify-content-center">
              <p class='text text-left' *ngFor="let item of servicios; let i=index">{{item}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      
    <ng-container *ngIf="datos.almuerzo != 'N' "> 
      <p class='text text-primary'>Suplementos</p>
      <div class="col-xl-3 mb-3">
        <div class="card py-2 btn ">
          <div class="card-body estrecho ">
            <div class="row">
              <div class="col-10 p-0">
                <ng-container class="d-flex justify-content-center align-items-center flex-row" *ngIf="datos.almuerzo=='O'; else elseAlmuerzo">
                  <nb-checkbox (checkedChange)="toggle($event)">Añadir Almuerzo/Comida</nb-checkbox>
                </ng-container>
                <ng-template #elseAlmuerzo>
                  <ng-container class="d-flex justify-content-center align-items-center flex-row" *ngIf="datos.almuerzo=='I'; else elseNoIncAl">
                    <nb-checkbox (checkedChange)="toggle($event)" checked>Añadir Almuerzo/Comida</nb-checkbox>
                  </ng-container>
                  <ng-template #elseNoIncAl>
                    <p></p>
                  </ng-template>
                </ng-template>
              </div>
              <div class="col-2 p-0">
                <ng-container class="d-flex justify-content-center align-items-center flex-row" *ngIf="datos.almuerzo =='O' || datos.almuerzo == 'I'">
                  <label class='text-primary mb-0' style="margin-top: 3px!important;">{{datos.precioS}}€</label>
                </ng-container>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </ng-container>
    
      <p class='text text-primary'>Descuentos</p>
      <div class="col-xl-3 mb-3">
        <div class="card py-2 btn ">
          <div class="card-body estrecho">
            <div class="row mr-1 ml-1 d-flex justify-content-between align-items-center">
              <p class='text-dark'>Niños (hasta {{datos.edad_nino}} años)</p>
              <p class='text-primary'>{{datos.descuento_nino}}%</p>
            </div>
            <div class="row mr-1 ml-1 d-flex justify-content-between align-items-center">
              <p class='text-dark'>Seniors (a partir de {{datos.edad_jubilado}} años)</p>
              <p class='text-primary'>{{datos.descuento_jubilado}}%</p> 
            </div>
          </div>
        </div>
      </div>


      <ng-container *ngFor="let item of personas; let idx = index">

        <div class="col-xl-4  mb-3">
          <div class="card py-2 btn">
            <div class="card-body estrecho">
              <nb-toggle (change)="comprobar(idx+1,item.id);" labelPosition="start">Contratar para esta persona</nb-toggle>
              <input class="mt-1" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Nombre" value="{{item.nombre}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Apellidos" value="{{item.apellidos}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="DNI" value="{{item.dni}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Edad" value="{{item.edad}}">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Habitación" value="{{item.hab}}">
            </div>
          </div>
        </div>

      </ng-container>

       <div class="col-xl-4  mb-3" *ngIf="clickeable == true">
          <div class="card py-2 btn">
            <div class="card-body estrecho">
              <input class="mt-1" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Nombre" [(ngModel)]="nomNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Apellidos" [(ngModel)]="apellidosNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="DNI" [(ngModel)]="dniNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Edad" [(ngModel)]="edadNewUser">
              <input class="mt-2" type="text" nbInput fullWidth status="basic" shape="semi-round" placeholder="Habitación" [(ngModel)]="habitacionNewUser">
              <button type="button" class="btn btn-info text-center text-white mt-2 w-100" (click)="addUser();">Añadir usuario a la excursion</button>
            </div>
          </div>
        </div> 

           
      <div class="col-xl-4  mb-3">
        <div class="card py-2 btn" (click)="isClick();">
          <div class="card-body estrecho">
            <p>AÑADIR PERSONA</p>
          </div>
        </div>
      </div>
              

      <div class='d-flex justify-content-center align-items-center w-100'>
        <button type="button" class="btn btn-success text-center w-100" (click)="enviarDatos();" >Comprar</button>
      </div>


    </div>
  </div>


</section> 