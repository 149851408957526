import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import {
  NbMenuModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbUserModule,
  NbIconModule, NbButtonModule, NbTreeGridModule, NbRadioModule, NbInputModule, NbListModule, NbDialogModule, NbToggleModule, NbThemeModule, NbChatModule, NbCalendarModule, NbCalendarRangeModule, NbSelectModule
} from '@nebular/theme';
import { NotFoundComponent } from './not-found/not-found.component';

import { TextoComponent } from './temario/texto/texto.component';
import { PerfilComponent } from './perfil/perfil.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NbCardModule, NbCalendarKitModule, NbRouteTabsetModule, NbCheckboxModule, NbAccordionModule} from 'src/framework/theme/public_api';
import { HttpClientModule } from '@angular/common/http';


import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { CountdownModule } from 'ngx-countdown';

import { NgFallimgModule } from 'ng-fallimg';
import { BienvenidaComponent } from '../bienvenida/bienvenida.component';
import { DocumentacionComponent } from './documentacion/documentacion.component';

import { NotificacionesComponent } from '../header/notificaciones/notificaciones.component';
import { ExcursionesComponent } from './excursiones/excursiones.component';
import { ExcursionComponent } from './excursiones/excursion/excursion.component';
import { ContratacionComponent } from './excursiones/contratacion/contratacion.component';
import { PagoComponent } from './excursiones/pago/pago.component';

import { ApiService } from '../services/conexionApi.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MichatComponent } from './chats/michat/michat.component';
import { InicioComponent } from './inicio/inicio.component';
import { RentacarComponent } from './rentacar/rentacar.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { DudasComponent } from './dudas/dudas.component';
import { InformeVentasComponent } from './informeventas/informeventas.component';
import { ConoceTuHotelComponent } from './conocetuhotel/conocetuhotel.component';
import { ChatsComponent } from './chats/chats.component';
import { ResumenComponent } from './excursiones/resumen/resumen.component';
import { GenerarTicketComponent } from './excursiones/generarticket/generarticket.component';
import { RecomendacionesComponent } from './recomendaciones/recomendaciones.component';
import { ContratacionRComponent } from './recomendaciones/contratacionR/contratacionR.component';
import { GenerarTicketRComponent } from './recomendaciones/generarticketR/generarticketR.component';
import { PagoRComponent } from './recomendaciones/pagoR/pagoR.component';
import { RecomendacionComponent } from './recomendaciones/recomendacion/recomendacion.component';
import { ResumenRComponent } from './recomendaciones/resumenR/resumenR.component';
import { GuiasComponent } from './chats/guias/guias.component';
import { LugarInteresComponent } from './puntosinteres/lugarinteres/lugarinteres.component';
import { PuntosInteresComponent } from './puntosinteres/puntosinteres.component';
import { LectorQRComponent } from './lectorqr/lectorqr.component';
import { ItinerarioComponent } from './puntosinteres/itinerario/itinerario.component';
import { ItinerarioGuiadoComponent } from './puntosinteres/itinerario-guiado/itinerario-guiado.component';
import { NotificacionComponent } from '../header/notificaciones/notificacion/notificacion.component';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { EncuestaComponent } from './encuestas/encuesta/encuesta.component';
import { WhispersComponent } from './whispers/whispers.component';
import { WhisperComponent } from './whispers/whisper/whisper.component';
import { LocalizadorComponent } from './excursiones/localizador/localizador.component';
import { EscaneoQrComponent } from './lectorqr/escaneo-qr/escaneo-qr.component';



@NgModule({
  declarations: [
    NotFoundComponent,
    TextoComponent,
    PerfilComponent,
    BienvenidaComponent,
    DocumentacionComponent,
    NotificacionesComponent,
    ExcursionesComponent,
    ExcursionComponent, 
    ContratacionComponent,
    PagoComponent,
    MichatComponent,
    InicioComponent,
    RentacarComponent,
    CondicionesComponent,
    PoliticasComponent,
    DudasComponent, 
    InformeVentasComponent,
    ConoceTuHotelComponent,
    ChatsComponent,
    ResumenComponent,
    GenerarTicketComponent,
    RecomendacionesComponent,
    ContratacionRComponent,
    GenerarTicketRComponent,
    PagoRComponent,
    RecomendacionComponent,
    ResumenRComponent,
    GuiasComponent,
    LugarInteresComponent,
    PuntosInteresComponent,
    LectorQRComponent,
    ItinerarioComponent,
    ItinerarioGuiadoComponent,
    NotificacionComponent,
    EncuestasComponent,
    EncuestaComponent, 
    WhispersComponent,
    WhisperComponent,
    LocalizadorComponent,
    EscaneoQrComponent

      

  ],

  imports: [
    CommonModule,
    PagesRoutingModule,
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbButtonModule,
    NbContextMenuModule,
    NbUserModule,
    NbIconModule,
    NbTreeGridModule,
    NgxChartsModule,
    NbRadioModule,
    NbInputModule,
    FormsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    NbCalendarKitModule,
    NbListModule,
    CountdownModule,
    NgFallimgModule,
    NbRouteTabsetModule,
    NbDialogModule.forRoot(),
    NbCheckboxModule,
    NbToggleModule,
    NbThemeModule,
    NgxDaterangepickerMd.forRoot(),
    FontAwesomeModule,
    NbToggleModule,
    ReactiveFormsModule,

    NbCalendarModule,
    NbCalendarRangeModule,

    NbChatModule.forRoot(),
    NbAccordionModule,
    NbSelectModule

   
   
  ],
  providers: [ApiService],
})
export class PagesModule { }
