<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">{{titulo}}</h6>
</div>

<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-1 pt-1 mb-3" id="services">
    <div class="container-fluid">

  <div class="row mb-5 mt-5">

    <ng-container *ngFor="let item of datos; let i=index">
      <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
      
        <div class="card shadow py-2 btn" style="background-image:url({{item.img}}); width:100%; background-repeat: no-repeat; background-size: cover;" (click)="verExcursion(i);" >
        <!-- <div class="card shadow py-2 btn" style="background-image:url('assets/img/bar ejemplo.jpg'); width:100%" (click)="verExcursion(i);" > -->
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <div class="col d-flex flex-column justify-content-start align-items-end">
                <p class="text-white desde">desde</p>
                <p class='text-white font-weight-bold'>{{item.precio}}€</p>
              </div>
              <div class="col d-flex justify-content-start align-items-end pb-1">
                <p class="text-white font-weight-bold title">{{item.nombre}}</p>
              </div> 
          </div>
        </div>
      </div>
    </ng-container>
    
    

    <!-- <div class="col-xl-6 col-md-12 mb-3">
        <div class="card shadow py-2 btn" style="background-image:url('assets/img/comida ejemplo.jpg'); width:100%"  [routerLink]="['/pages/excursiones/id/2']">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <div class="col d-flex flex-column justify-content-start align-items-end">
                <p class="text-white desde">desde</p>
                <p class='text-white font-weight-bold'>40€</p>
              </div>
              <div class="col d-flex justify-content-start align-items-end pb-1">
                <p class="text-white font-weight-bold title">Marbella y Puerto Banús</p>
              </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-6 col-md-12 mb-3">
        <div class="card shadow py-2 btn" style="background-image:url('assets/img/cafe ejemplo.jpg'); width:100%"  [routerLink]="['/pages/excursiones/id/3']">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <div class="col d-flex flex-column justify-content-start align-items-end">
                <p class="text-white desde">desde</p>
                <p class='text-white font-weight-bold'>50€</p>
              </div>
              <div class="col d-flex justify-content-start align-items-end pb-1">
                <p class="text-white font-weight-bold title">Sevilla</p>
              </div>
          </div>
        </div>
      </div> -->


   </div>
</div>
</section>