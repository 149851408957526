import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-informe',
  templateUrl: './informeventas.component.html',
  styleUrls: ['./informeventas.component.scss']
})


export class InformeVentasComponent implements OnInit {
  user: any;
  quienSoy: string;
  public load: boolean;


  constructor(private cdr: ChangeDetectorRef, private authService: NbAuthService, private zone: NgZone,private router: Router ) {
    this.load = false;

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax;     
        
        if( this.quienSoy != "hotel" && this.quienSoy != "guia"){
          this.zone.runOutsideAngular(() => {
            this.router.navigate(['pages/error']);
          });
        }else{
          window.location.href = "https://back.cisnea.com/assisback/";
        }
        
      }
    }); 

  }

  ngOnInit() { 
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

}