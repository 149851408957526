import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthResult, NbAuthService, NbTokenService } from 'src/framework/auth/public_api';
import { ApiService } from '../services/conexionApi.service';
import { Peticion } from '../services/peticion';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  
    peticion = new Peticion();
    datos:any = {};
    token:any = {};
    user: any;
    pax = "";
    
  constructor( private https: ApiService, protected service: NbAuthService,protected cd: ChangeDetectorRef,private router: Router, private t: NbTokenService) {
    Object.assign(this.datos);
    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        
        
      }
    });
  }
  ngOnInit(): void {
   var t = localStorage.getItem('auth_app_token');
   if(this.pax != ""){
    this.router.navigate(['/pages/bienvenida']);
   }
    this.obtenerDatos({ token: t });
    
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/auth/usuarios.php").subscribe(
      info => {
        this.datos = info;
      },
      err => {
        console.log(err);
      }
    );
  }

  // Este es el metodo que envia los datos a la base de datos
  postGuardarSesion(body: Peticion, url:string) {
    this.https.postRespuesta(body,url).subscribe(
      data => {
        this.token = data.token;
        localStorage.removeItem('auth_app_token');
        localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value':this.token}));
        
        this.service.refreshToken('email', {token: this.token});
        this.t.set(this.token);
            
      },
      err => {}
    );

  }

  cargarUsuario(idUsuario){
    var t = localStorage.getItem('auth_app_token');
    this.postGuardarSesion({tipoTest: idUsuario,  token: t }, "https://api.cisnea.com/auth/generarusuario.php"); 
    this.router.navigate(['/pages/bienvenida']);
    
  }


}
