<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Conexiones con el guía</h6>
  </div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>

  <ng-container *ngIf="pax == 'guia'; else elseTemplate">
    <div class="row w-100 mt-5" >
      <div class="col">
        <form action="" [formGroup]="nuevoCanal">
          <label for="">Nombre del whisper</label>
          <input type="text" class="form-control" formControlName="nombre" placeholder="Crea tu whisper">
          <small class="text-danger" *ngIf="nuevoCanal.invalid && nuevoCanal.touched">¡Debes escribir un nombre para el whisper!</small>
        </form>
        <label for="" class="mt-1">Excursión</label>
          <nb-select class="d-block w-100" placeholder="Excursión" [(ngModel)]="selectedItemNgModel" > 
            <nb-option *ngFor="let item of excursiones" value="{{item.id}}">{{item.nombre}}</nb-option>
          </nb-select>
        <button class="btn btn-primary mt-4" (click)="crearCanal()" >Crear sala</button>
      </div>
  </div>
  </ng-container>



  <ng-template #elseTemplate>
    <ng-container *ngIf="disponibles == false; else elseTemplate">
      <div class="row mt-5 d-flex flex-column">
        <div class="col">
          <h6 class="mt-2">Tus whispers</h6>
          <p class="text-center mt-2 " *ngIf="canales.length == 0"> En este momento no tienes whispers disponibles. </p> 
        </div>
      </div>
      <div class="row d-flex" >
        <div class="col-6" *ngFor="let canal of canales">
            <button class="btn btn-primary mt-2 mb-4 w-100" (click)="goToWhisper(canal.id)">{{canal.exc | titlecase}}</button>
        </div>
      </div>
  
