<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<!-- <div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div> -->
<nb-chat [title]="nombre" size="medium" status="primary" class="chatEspaciado">
    <nb-chat-message *ngFor="let message of messages" [type]="message.type" [message]="message.text"
        [reply]="message.reply" [sender]="message.user.name" [date]="message.date" [files]="message.files"
        [quote]="message.quote" [latitude]="message.latitude" [longitude]="message.longitude"
        [avatar]="message.user.avatar"></nb-chat-message>
   
        <nb-chat-form  #divClick (send)="sendMessage($event, 'Yo,', 'http://www.reactiongifs.com/r/wnd1.gif', true)"
            [showButton]="false" [dropFiles]="true" [messagePlaceholder]="'Escribe tu mensaje'">
        </nb-chat-form>


</nb-chat>