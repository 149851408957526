import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TextoComponent } from './temario/texto/texto.component';
import { PerfilComponent } from './perfil/perfil.component';
import { BienvenidaComponent } from '../bienvenida/bienvenida.component';
import { UsuariosComponent } from '../usuarios/usuarios.component';

import { NotificacionesComponent } from '../header/notificaciones/notificaciones.component';
import { ExcursionesComponent } from './excursiones/excursiones.component';
import { ExcursionComponent } from './excursiones/excursion/excursion.component';
import { ContratacionComponent } from './excursiones/contratacion/contratacion.component';
import { PagoComponent } from './excursiones/pago/pago.component';

import { MichatComponent } from './chats/michat/michat.component';
import { DocumentacionComponent } from './documentacion/documentacion.component';
import { InicioComponent } from './inicio/inicio.component';
import { RentacarComponent } from './rentacar/rentacar.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { DudasComponent } from './dudas/dudas.component';
import { InformeVentasComponent } from './informeventas/informeventas.component';
import { ConoceTuHotelComponent } from './conocetuhotel/conocetuhotel.component';
import { ChatsComponent } from './chats/chats.component';
import { ResumenComponent } from './excursiones/resumen/resumen.component';
import { GenerarTicketComponent } from './excursiones/generarticket/generarticket.component';
import { PuntosInteresComponent } from './puntosinteres/puntosinteres.component';
import { LugarInteresComponent } from './puntosinteres/lugarinteres/lugarinteres.component';
import { LectorQRComponent } from './lectorqr/lectorqr.component';
import { ItinerarioComponent } from './puntosinteres/itinerario/itinerario.component';
import { ItinerarioGuiadoComponent } from './puntosinteres/itinerario-guiado/itinerario-guiado.component';
import { NotificacionComponent } from '../header/notificaciones/notificacion/notificacion.component';
import { GuiasComponent } from './chats/guias/guias.component';

import { EncuestasComponent } from './encuestas/encuestas.component';
import { EncuestaComponent } from './encuestas/encuesta/encuesta.component';
import { WhispersComponent } from './whispers/whispers.component';
import { WhisperComponent } from './whispers/whisper/whisper.component';
import { LocalizadorComponent } from './excursiones/localizador/localizador.component';
import { EscaneoQrComponent } from './lectorqr/escaneo-qr/escaneo-qr.component';


const routes: Routes = [

  {
    path: 'bienvenida',
    component: BienvenidaComponent,
  },

  {
    path: 'usuarios',
    component: UsuariosComponent,
  },

  // {
  //   path: 'chats',
  //   loadChildren: () => import('../../../framework/theme/components/chat/chat.module').then(m => m.NbChatModule),
  
  // },

  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'qr',
        children: [
          {
            path: '',
            component: LectorQRComponent
          },
          {
            path: 'scan',
            component: EscaneoQrComponent
          }
        ],
      },
      {
        path: 'inicio',
        component: InicioComponent,
      },
      {
        path: 'rentacar',
        component: RentacarComponent,
      },
      {
        path: 'notificaciones',
        
        children:
        [
          {
            path:'',
            component: NotificacionesComponent
          },
          {
            path:'mensaje/:id',
            component: NotificacionComponent
          }
        ]
      },
      {
        path: 'encuestas',
        children:
        [
          {
            path:'',
            component: EncuestasComponent
          },
          {
            path:':id',
            component: EncuestaComponent
          }
        ]
        
      },
      {
        path: 'documentacion',
        component: DocumentacionComponent,
      },
      {
        path: 'lugares',
        children: [
          {
            path: ':tipo',
            children:[
              {
                path: '',
                component: ExcursionesComponent,
              },
              {
                path: 'id/:numero',
                children: [
                  {
                    path: '',
                    component: ExcursionComponent,
                  },
                  {
                    path: 'loc',
                    component: LocalizadorComponent,
                  },
                  {
                    path: 'contratacion/:localizador',
                    children: [
                      {
                        path: '',
                        component: ContratacionComponent,
                      },
                      {
                        path: 'resumen/:insercion',
                        children: [
                          {
                            path: '',
                            component: ResumenComponent,
                          },
                          {
                            path: 'pago',
                            children:[
                              {
                                path: '',
                                component: PagoComponent
                              },
                              {
                                path:'terminar',
                                component: GenerarTicketComponent
                              }
                            ]
                          },
                        ]
                        
                      },
                      
                    ],
                  },
            ]

          },
          
            ],
            
          },
        ],
      },
      {
        path:'chats',
        children: [
          {
            path: '',
            component: ChatsComponent ,
          },
          {
            path: 'michat/:id',
            component: MichatComponent,
          },
          {
            path: 'guia',
            component: GuiasComponent,
          },
        ]
      },
      {
        path:'puntosinteres',
        children: [
          {
            path: '',
            component: PuntosInteresComponent,
          },
          {
            path: 'guiavirtual/itinerario/:it',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    component: ItinerarioComponent
                  },
                  {
                    path: 'guiado',
                    component: ItinerarioGuiadoComponent
                  }

                ],
              }
            ],
          },
          {
            path: 'PI/:pi',
            children: [
              {
                path: '',
                component: LugarInteresComponent
              }
            ],
          }
        ]
      },
      {
        path:'whispers',
        children: [
          {
            path: '',
            component: WhispersComponent
          },
          {
            path: 'con/:id',
            component:WhisperComponent
          }
        ],
      },
      {
        path:'informeventas',
        component: InformeVentasComponent,
      },
      {
        path:'conocetuhotel',
        component: ConoceTuHotelComponent,
      },
      {
        path: 'perfil',
        component: PerfilComponent,
      },
      {
        path: 'condiciones',
        component: CondicionesComponent,
      },
      {
        path: 'politicas',
        component: PoliticasComponent,
      },
      {
        path: 'dudas',
        component: DudasComponent,
      },
      {
        path: 'temario',
        children: [
          {
            path: '',
            component: TextoComponent,
          }
        ]
      },

      { path: '**', component: NotFoundComponent },
      
    ]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
