<div class="container text-center p-2">
    <div class="d-flex justify-content-start flex-column">
        <img src={{logo}} alt="" class="club ml-auto mr-auto pt-2">
        <img src={{img}} alt="" class="pres ml-auto mr-auto">
    </div>

    <p class="sub-title" *ngIf="tamano==0; else elseTemplate">{{mensaje}}</p>
    <ng-template #elseTemplate>
        <ng-container *ngFor="let item of datos; let i=index">
            <p class="sub-title" >{{item.texto}}</p>
        </ng-container>
        
        
    </ng-template>
    
    <button nbButton fullWidth status="primary" size="large" (click)="entrar()" >
        Bienvenido
    </button>
</div>