<nb-layout>
      <nb-layout-header fixed>
            <app-header class="w-100"></app-header>
      </nb-layout-header>

      <!-- <nb-sidebar left fixed valor state="collapsed" responsive class="menu-sidebar" tag="menu-sidebar" (click)="cerrar()">
            <nb-menu [items]="items" ></nb-menu>
      </nb-sidebar> --> 

      <nb-layout-column>
            <router-outlet></router-outlet>
            <!-- <nb-tabset>
                  <nb-tab tabTitle="Documentación" ></nb-tab>
                  <nb-tab tabTitle="Excursiones"></nb-tab>
                  <nb-tab tabTitle="Chat Box" ></nb-tab>
                  <nb-tab tabTitle="Guí Virtual"></nb-tab>
                  <nb-tab tabTitle="Whisper" ></nb-tab>
            </nb-tabset> -->
            <!-- <nb-route-tabset [tabs]="tabs"></nb-route-tabset> -->
      </nb-layout-column>
      
           
      
     
</nb-layout>