import { StringLiteralType } from "typescript";

export class NbUser {

  constructor(public id?: number,
              public email?: string,
              public password?: string,
              public rememberMe?: boolean,
              public terms?: boolean,
              public confirmPassword?: string,
              public fullName?: string,
              public edad?:number,
              public nombre?:string,
              public habitacion?:string,
              public apellidos?:string,
              public localizador?:string            
              
              ) {
  }
}
