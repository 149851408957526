import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})


export class EncuestaComponent implements OnInit {

  peticion = new Peticion(); 
  datos:any = [];
  user:any;
  pax:any;
  public load: boolean;


  constructor(private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router,private zone: NgZone,protected service: NbAuthService, private route: ActivatedRoute) {
    this.load = false;
    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if( this.pax == "hotel" || this.pax == "cliente_hotel"){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }
      }
    });


  }
  
  
  


  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    var idEnc = this.route.snapshot.paramMap.get('id');
    this.obtenerDatos({ token: t,id:idEnc });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  

  goBack(){
    this.load = false;
    this._location.back();
  }

  keyPress(pregunta,event: KeyboardEvent) {
    const respuesta = event.key;
    this.comprobar(pregunta,respuesta,0);
  } 

  preguntas = [];
  respuestas = [];

  comprobar(pregunta, respuesta, indice){
    if(this.preguntas.indexOf(pregunta) == -1){
      this.preguntas.push(pregunta);
      if (indice==0) {
        this.respuestas.push(respuesta);
      } else {
        this.respuestas.push(indice);
      }
      
    }else{
      var pos = this.preguntas.indexOf(pregunta);
      if (indice==0) {
        this.respuestas[pos] += respuesta;
      } else {
        this.respuestas[pos] = indice;
      }
    }
    console.log(pregunta+" resp: "+respuesta+" indice "+indice);
  }

  enviarDatos(){
    var t = localStorage.getItem('auth_app_token');
    this.https.postRespuesta({token:t, preguntas : this.preguntas, respuestas: this.respuestas }, "https://api.cisnea.com/guardarEncuesta.php").subscribe(
      info => {
        this.router.navigateByUrl("/pages/inicio");
      },
      err => {
        console.log(err);
      }
    );

    
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/encuestas.php").subscribe(
      info => {
        this.datos = info;
        console.log(this.datos);
        this.cdr.markForCheck ();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }
}
