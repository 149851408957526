<a class="history-back" (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px !important"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Lugar de interés</h6>
</div>

<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
  <div class="container-fluid">
      <div class="row">
        <img
          class="img-fluid rounded guia-img"
          src="{{datos.img}}"
          alt="{{datos.nombre}}"
        />
  
        <div class="card shadow w-100 info mb-5">
          <div class="card-body mb-3 interes-card">
            <h5>{{datos.nombre | titlecase}}</h5>
            <div class="row">
              <div class="col-6 d-flex">
                <i class="far fa-clock" style="font-size: 30px !important"></i>
                <div class="col">
                  <h6>Duración</h6>
                  <p class="highlight">{{datos.duracion}} horas</p>
                </div>
              </div>
              <div class="col-6 d-flex">
                <i class="far fa-map" style="font-size: 30px !important"></i>
                <div class="col">
                  <h6>Paradas</h6>
                  <p class="highlight">{{datos.num_paradas_totales}}</p>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <h6>Punto de partida</h6>
                <div class="col d-flex mt-2 pl-1 ">
                  <i
                    class="fas fa-map-marked-alt"
                    style="font-size: 30px !important"
                  ></i>
                  <div class="col mt-1">
                    <p class="highlight">{{datos.primera_parada}}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
  
            <div class="row">
              <div class="col">
                <h5>Descripción</h5>
                <p>{{datos.descripcion}}</p>
                
              </div>
            </div>
          </div>
        </div>
    </div>
      
  
       

    <div class="d-flex justify-content-center align-items-center comprar">
      <button type="button" class="btn text-center btn-success" routerLink="guiado" >Empezar ruta</button> 
    </div>
  </div>
</section>
