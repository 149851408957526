import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';

@Component({
  selector: 'app-pagoR',
  templateUrl: './pagoR.component.html',
  styleUrls: ['./pagoR.component.scss']
})


export class PagoRComponent implements OnInit {

  checkedTarjeta = false;
  checkedEmail = false;
  id = this.route.snapshot.paramMap.get('numero');
  insercion = this.route.snapshot.paramMap.get('insercion');
  total;
  peticion = new Peticion();
  datos:any = [];
  infoExcUsuarios:any;
  totales = 0;
  exc_contrats:any;
  constructor(private _location: Location,private route: ActivatedRoute,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router ) {}

  ngOnInit() { 
    this.total = this.insercion.split("_")[2];
    var t = localStorage.getItem('auth_app_token');
    this.exc_contrats = localStorage.getItem('excursiones_contratadas');
    // this.obtenerDatosExcursion({ token: t,insercion:this.insercion,excursion:this.exc_contrats });
  }

  goBack(){
    this._location.back();
  }

  toggleTarjeta(checked: boolean) {
    this.checkedTarjeta = checked;
  }

  toggleEmail(checked: boolean) {
    this.checkedEmail = checked;
  }

  goTerminar(){
    localStorage.removeItem("excursiones_contratadas");
    this.router.navigate(['terminarR'],{relativeTo: this.route});
  }

  obtenerDatosExcursion(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/datosExcComprada.php").subscribe(
        info => {
            this.cdr.markForCheck ();
            info.forEach(element => {
            this.totales += element.total;  
            });
        },
        err => {
            console.log(err);
        }
        );
}

}