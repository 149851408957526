import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ApiService } from '../../services/conexionApi.service';
import { Subscription } from 'rxjs';
import QrScanner from 'qr-scanner';

QrScanner.WORKER_PATH = 'assets/js/qr-scanner-worker.min.js';


@Component({
  selector: 'app-qr',
  templateUrl: './lectorqr.component.html',
  styleUrls: ['./lectorqr.component.scss']
})


export class LectorQRComponent implements OnInit {
  user: any;
  quienSoy: string;
  public load: boolean;
  respuesta: any;
  subscription: Subscription;
  //videoElem;
  cameraList;
  // camQrResult;




  constructor(private cdr: ChangeDetectorRef,private _location: Location,private authService: NbAuthService,private zone: NgZone, private swPush: SwPush, private https: ApiService,private router: Router ) {
    this.load = false;
    //console.log(this.videoElem)
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax;    
        this.load = true;
        if(this.quienSoy != "guia"){
            this.zone.runOutsideAngular(() => {
                this.router.navigate(['pages/error']);
              });
        }
      }
    }); 
               
  }


  
  ngOnInit() { 
    //this.videoElem  = document.querySelector('video');
    // this.camQrResult = document.querySelector('.cam-qr-result');

    //localStorage.removeItem('excursiones_contratadas');
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
    
  }

  goToCall(){
    this.router.navigate(['pages/qr/scan']);
  }


  goBack(){
    this.load = false;
    // this.stopScan();
    this._location.back();
  }

}