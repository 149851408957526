import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { isThisTypeNode } from 'typescript';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit {
  user: any;
  quienSoy: string;
  public load: boolean;
  respuesta: any;
  tieneNotif: boolean = true;
  token: any;
  pushSubscription: PushSubscription;
  info:any;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: NbAuthService,
    private swPush: SwPush,
    private https: ApiService
  ) {

    this.load = false;
    
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.quienSoy = this.user.data.pax;
        this.load = true;
        console.log('soy ' + this.quienSoy);
      }
    });




  }

  ngOnInit() {
    localStorage.removeItem('excursiones_contratadas');
    var t = localStorage.getItem('auth_app_token');
    this.token = t;

    this.obtenerDatos({ token: t });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);

    if(this.swPush.isEnabled){
      this.swPush.subscription.subscribe((res) => {
        this.pushSubscription = res;

        if(this.pushSubscription === null){
          this.tieneNotif = false; 
          localStorage.setItem('notiPush', JSON.stringify(this.tieneNotif))
        }else{
          this.tieneNotif = true;
          localStorage.setItem('notiPush', JSON.stringify(this.tieneNotif))
        }
        console.log("Tiene notif: " + this.tieneNotif)
      })

      if(localStorage.getItem('notiPush') == null || localStorage.getItem('notiPush') == ''){
        localStorage.setItem('notiPush', "false");
        this.tieneNotif = JSON.parse(localStorage.getItem('notiPush'));
      }else{
        this.tieneNotif = JSON.parse(localStorage.getItem('notiPush'));
      }


    }else{
      return;
    }

  }

  pushSubscribe(){
      this.swPush
        .requestSubscription({
          serverPublicKey: environment.VAPID_KEY,
        })
        .then((sub) => {
          this.https
            .addSubscription(
              sub,
              localStorage.getItem('auth_app_token'),
              'https://api.cisnea.com/push_subscription.php'
            )
            .subscribe((res) => {
              this.swPush.subscription.subscribe((res) => {
                this.pushSubscription = res;
                this.tieneNotif = true;
                localStorage.setItem('notiPush', JSON.stringify(this.tieneNotif))
              })

            });

        })
        .catch((err) => {
          this.respuesta = err;
        });
    
  }

  obtenerDatos(body: Peticion) {
    this.https
      .postRespuesta(body, 'https://api.cisnea.com/todasEncuestas.php')
      .subscribe(
        (info) => {
          this.info = info;
          this.load = true;
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
