<div class="header-container">
    <div class="logo-container">
        <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle ">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a> -->
        <a alt="inicio" class="logo  control-item" routerLink="/pages/inicio"><img class="img-logo" src={{img}} alt="Logo de la empresa"></a>
    </div>

    <div class="userMenuContainer d-flex justify-content-end align-items-center">
       
            <ng-container *ngIf="notificacionesNuevas == false; else elseTemplate">
                <a class=" control-item" routerLink="notificaciones"><i class="far fa-bell  mr-3 text-info" style="font-size:25px!important;"></i></a>
            </ng-container>
            <ng-template #elseTemplate>
                <a class=" control-item" routerLink="notificaciones"><i class="far fa-bell  mr-3 text-danger" style="font-size:25px!important;"></i></a>
            </ng-template>
   
        
        
        
        
       <app-menu-user></app-menu-user> 
    </div> 
</div>