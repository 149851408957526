import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NbSidebarService }from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ApiService } from '../services/conexionApi.service';
import { Peticion } from '../services/peticion';
import { MenuUserComponent } from './menu-user/menu-user.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
  
})
export class HeaderComponent implements OnInit{
  user = {};
  img = "";
  notificacionesNuevas = false;
  t = localStorage.getItem('auth_app_token');
  pax = "";
  other:any;
  accedo = true;
  constructor (private sidebarService: NbSidebarService, private https: ApiService,private cdr: ChangeDetectorRef,protected service: NbAuthService){

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.other = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.pax = this.other.data.pax;
        if(this.pax == "hotel" || this.pax == "guia"){
          this.accedo = false;
        }
      }
    });

   }
  ngOnInit(){ 
    if (this.accedo) {
      this.obtenerDatos({ token: this.t });
      this.isNotificacionNueva();
      
      setInterval(() => {
        this.isNotificacionNueva();
      }, 5000); 
    }

  }

  isNotificacionNueva(){
    var body : Peticion = { token: this.t };
    this.https.postRespuesta(body, "https://api.cisnea.com/nuevasNotificaciones.php").subscribe(
      info => {
        this.notificacionesNuevas = info;
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/bienvenida.php").subscribe(
      info => {
        this.img = info.url_logo;
        console.log(info);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }
  
}
