import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input,Renderer2  } from '@angular/core';
import { Location } from '@angular/common';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import {formatDate} from '@angular/common';



import { NbCalendarRange, NbDateService } from '@nebular/theme';

@Component({
  selector: 'app-contratacionR',
  templateUrl: './contratacionR.component.html',
  styleUrls: ['./contratacionR.component.scss']
})


export class ContratacionRComponent implements OnInit {

  checked = false;
  toggleNgModel = false;
  clickeable = false;
  peticion = new Peticion();
  datos:any = [];
  servicios:any = [];
  personas:any = [];
  fechas:any = [];
  paxSeleccionados:any = [];
  idPaxSeleccionados:any = [];

  // con dias habilitados 
  date: Date;
  min: Date;
  max: Date;

  // DATOS NUEVO USER
  nomNewUser;
  apellidosNewUser;
  dniNewUser;
  edadNewUser;
  habitacionNewUser;



  constructor(private renderer: Renderer2,private activatedRoute:ActivatedRoute,private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router, private route: ActivatedRoute,protected dateService: NbDateService<Date>) {
    this.date = this.dateService.today();
    this.min = this.dateService.addDay(this.date, -7);
    this.max = this.dateService.addDay(this.date, 3);
  }

  get monthStart(): Date {
    return this.dateService.getMonthStart(new Date());
  }

  get monthEnd(): Date {
    return this.dateService.getMonthEnd(new Date());
  }

  selected: { startDate: Date, endDate: Date };
  origen: Text;
  destino: Text;
  habPer: Text;

  id = this.route.snapshot.paramMap.get('numero');
  t = localStorage.getItem('auth_app_token');
  ngOnInit() {
    this.obtenerDatos({ token: this.t });
  }

  toggle(checked: boolean) {
    this.checked = checked;
  }

  goBack() {
    this._location.back();
  }

  generarRango(){
    var fechaI = new Date(this.fechas[0]);
    var fechaF = new Date(this.fechas[1]);

    this.date = this.dateService.today();
    this.min = fechaI;
    this.max = fechaF;

  }

  // @Input() focusOnInputOnValueChange: boolean;

  // handleInputValueUpdate() {
  //   if (this.focusOnInputOnValueChange) {
  //    this.hostRef.nativeElement.focus();
  //   }
  //  }

  isClick(){
    this.clickeable = true;
    this.cdr.markForCheck ();
  }

  addUser(){
    let peticion = {token:this.t,nombre:this.nomNewUser, apellidos:this.apellidosNewUser,dni:this.dniNewUser,edad:this.edadNewUser,hab:this.habitacionNewUser, newRegister:false};
    this.https.postRespuesta(peticion, 'https://api.cisnea.com/addUser.php').subscribe(
      info => {
        this.obtenerDatos({ token: this.t });
        this.nomNewUser = "";
        this.dniNewUser = "";
        this.edadNewUser = "";
        this.apellidosNewUser = "";
        this.habitacionNewUser = "";
        this.clickeable = false;
      },
      err => {
        console.log(err);
      }
    );
  }

   
  comprobar(indice, idPerson){
    
    if(this.paxSeleccionados.indexOf(idPerson) == -1){
      this.paxSeleccionados.push(idPerson);
      this.idPaxSeleccionados.push(indice);
      this.checked = true;
      // console.log('no existe, lo añado'+this.checked);
      // console.log(this.paxSeleccionados);
    }else{
      var i = this.paxSeleccionados.indexOf( idPerson );
      this.paxSeleccionados.splice( i, 1 );
      this.idPaxSeleccionados.splice(i,1);
      this.checked = false;
      
      // console.log(this.paxSeleccionados);
      // console.log('existe, lo borro '+this.checked);
    }
    return this.checked;
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, 'https://api.cisnea.com/excursiones.php').subscribe(
      info => {
        this.datos = info.excursiones[this.id];
        this.fechas = info.excursiones[this.id].fechas.split(' -> ');
        this.personas = info.personas;
        console.log(this.datos);
        this.servicios = this.datos.servicios.split('-');
        this.cdr.markForCheck ();
        this.generarRango();
      },
      err => {
        console.log(err);
      }
    );
  }
 
  enviarDatos() {
    let idpax = "";
    let total = 0;
    for (let index = 0; index < this.idPaxSeleccionados.length; index++) {
      total += 16.95;
      if(index == (this.idPaxSeleccionados.length-1)){
        idpax+=this.idPaxSeleccionados[index].toString();
        
      }else{
        idpax+=this.idPaxSeleccionados[index].toString();
        idpax+=",";
      }
      
      console.log(idpax);
    }
    let f = formatDate(this.date, 'yyyy-MM-dd','en');
    this.router.navigate(['resumenR/'+idpax+"_"+f+"_"+total],{relativeTo: this.activatedRoute}); 

    // let comidaContratada = 'N';
    // if (this.checked==true) {
    //   comidaContratada = 'S';
    // }

    // let peticion : Peticion = {token:this.t, excursion:this.datos.id, fecha:f, paxIncluidos:idpax, comida:comidaContratada};

    // this.https.postRespuesta(peticion, 'https://api.cisnea.com/contratacionExcursion.php').subscribe(
    //   info => {
    //     var exc_contrats = localStorage.getItem('excursiones_contratadas');
    //     if(exc_contrats == null){
    //       exc_contrats = "";
    //     }
    //     localStorage.setItem('excursiones_contratadas', exc_contrats+','+info);
    //     this.router.navigate(['resumen/'+info],{relativeTo: this.activatedRoute}); 
    //   },
    //   err => {
    //     console.log(err); 
    //     var confirmado = confirm("Ya tiene contratada esta excursión");
    //     if (confirmado != true) {
    //       this.router.navigate(['/pages/inicio']); 
    //     }
        
    //   }
    // );
  }

}