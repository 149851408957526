import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';

@Component({
  selector: 'app-puntosinteres',
  templateUrl: './puntosinteres.component.html',
  styleUrls: ['./puntosinteres.component.scss']
})


export class PuntosInteresComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  public load: boolean;
  datosGuia:any = [];
  
  constructor(private _location: Location, private https: ApiService, private cdr: ChangeDetectorRef,private router: Router ) {
    this.load = false;
  }

  ngOnInit() {  
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t,tipo:"PI" });
    this.obtenerguiaVirtual({ token: t }) 
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones;
        this.cdr.markForCheck ();
        console.log(this.datos);
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }

  obtenerguiaVirtual(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/guiavirtual.php").subscribe(
      info => {
        this.datosGuia = info;
        this.cdr.markForCheck ();
        console.log(this.datosGuia);
        this.load = true; 
      },
      err => {
        console.log(err);
      } 
    );
  }


  verPI(id){
    this.router.navigateByUrl("/pages/puntosinteres/PI/"+id);
  }

  verGuia(id){
    this.router.navigateByUrl("/pages/puntosinteres/guiavirtual/itinerario/"+id);
  }
}