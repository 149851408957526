import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-excursion',
  templateUrl: './excursion.component.html',
  styleUrls: ['./excursion.component.scss']
})


export class ExcursionComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  public load: boolean;
  tipo = this.route.snapshot.paramMap.get('tipo');
  inicialTipo = "E";
  user: any;
  quienSoy: string;
  
  constructor(private zone: NgZone,private authService: NbAuthService,private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router, private route: ActivatedRoute ) {
    if (this.tipo=="excursiones") {
      this.inicialTipo = "E";
    } else {
      this.inicialTipo= "EYE";
    }
    this.load = false;
    
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax;       
      }
    }); 

  }

  id = this.route.snapshot.paramMap.get('numero');

  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t,tipo:this.inicialTipo });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }


  goBack(){
    this.load = false;
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones[this.id];
        this.cdr.markForCheck ();
        console.log(this.datos);
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }

  contratar(){
    if( this.quienSoy == "hotel" || this.quienSoy == "guia"){
      this.router.navigate(['loc'], {relativeTo: this.route});
    }else{
      this.router.navigate(['contratacion/user'], {relativeTo: this.route});
    }
  }

}