import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ActivatedRoute, Router } from '@angular/router';

import AgoraRTC, {IAgoraRTCClient} from "agora-rtc-sdk-ng";
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Peticion } from '../../services/peticion';
import { ApiService } from '../../services/conexionApi.service';


@Component({
  selector: 'app-whispers',
  templateUrl: './whispers.component.html',
  styleUrls: ['./whispers.component.scss'],
})
export class WhispersComponent implements OnInit {
  user: any;
  token: any;
  public load: boolean;
  disponibles :boolean = false;
  selectedItemNgModel:any;
  excursiones:any;
  t:any;
  pax: any;
  canales = [];

  nuevoCanal = this.fb.group(
    {
      nombre: ['test3', Validators.required]
    },
    
  )



  constructor(private https: ApiService,private route: ActivatedRoute,private cdr: ChangeDetectorRef, private _location: Location, private zone: NgZone, protected service: NbAuthService, private router: Router, private fb: FormBuilder) {
    this.load = false;

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.pax = this.user.data.pax;
        this.token = token.toString();
        if (this.pax == 'hotel' || this.pax == 'cliente_hotel') {
          this.zone.runOutsideAngular(() => {
            this.router.navigate(['pages/error']);
          });
        }
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
        this.load = true;
        this.cdr.markForCheck ();
    }, 500);
    this.t = localStorage.getItem('auth_app_token');
    if (this.pax == 'guia' ) {
      this.obtenerExcursiones({token:this.t});
    }else{
      setInterval(() => {
        this.obtenerCanales({token:this.t});
      }, 5000);  
    }
    
  }

  crearCanal(){
    if(this.nuevoCanal.valid){
      var c = this.nuevoCanal.controls.nombre.value;
      this.canales.push(this.nuevoCanal.controls.nombre.value);
      this.nuevoCanal.reset();
      this.iniciarSala({token:this.t,excursion:this.selectedItemNgModel,canal:c});//tiene que recibir: token,id_excursion,token
    }else{
      this.nuevoCanal.markAsTouched();
    }
    
  }

  goBack() {
    this.load = false;
    this._location.back();
  }

  goToWhisper(id){ //hace lo mismo que startcall pero con el id de insercion de ls bd
    if (this.pax != "guia") {
      this.accederCanal({token:this.t,canal:id});
    }
    this.router.navigate(['con/'+id], {relativeTo: this.route});
  }

  obtenerExcursiones(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/excursionesGuias.php").subscribe(
      info => {
          this.cdr.markForCheck ();
          this.excursiones = info;
      },
      err => {
          console.log(err);
      }
      );
  }

  iniciarSala(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/iniciarWhisper.php").subscribe(
      info => {
          this.cdr.markForCheck ();
          this.goToWhisper(info);//no se si esto va aqui
      },
      err => {
          console.log(err);
      }
      );
  }

  obtenerCanales(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/canalesGuias.php").subscribe(
      info => {
          this.cdr.markForCheck ();
          this.canales = info;
      },
      err => {
          console.log(err);
      }
      );
  }

  accederCanal(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/accederWhisper.php").subscribe(
      info => {
      },
      err => {
          console.log(err);
      }
      );
  }

}
