import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-rentacar',
  templateUrl: './rentacar.component.html',
  styleUrls: ['./rentacar.component.scss']
})


export class RentacarComponent implements OnInit {

  user:any;
  pax:any;

  constructor(private zone: NgZone,protected service: NbAuthService,private router: Router ) {

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if( this.pax == "cliente_hotel"){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }
      }
    });

  }

  ngOnInit() { 
   
  }

}