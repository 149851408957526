<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Chats</h6>
</div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-1 pt-1 mb-3" id="services">
  <div class="container-fluid">

    <div class="row mt-5">

      <!-- aqui es donde clasificaré el tipo de guia si llega a ser necesario -->
      <!-- <ng-container *ngIf="soyGuia == true; else elseTemplate"> -->
        <ng-container>
          <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
            <div class="card shadow-sm py-2 btn " [routerLink]="['guia']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2 d-flex justify-content-start">
                    <p class="text-dark">Todos los chats</p>
                  </div>
                  <div class="col-auto rounded-circle bg-info">
                    <i class="fas fa-comments text-white"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      <!-- </ng-container> -->


     







    </div>
  </div>
</section>