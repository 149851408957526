import { AfterViewInit, Component, Input, NgZone, OnInit } from '@angular/core';
import { NbAuthService, NbAuthJWTToken, NbAuthResult } from 'src/framework/auth/public_api';
import { Router } from '@angular/router';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';
import { FormControl } from '@angular/forms';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit  {

  peticion = new Peticion();
  datos: any = { };
  nombre;
  datosPerfil: any = { };
  user :any;
  nombreCompleto:any;
  localizadorCompleto:any;
  checked: boolean;
  token: any;
  pushSubscription: PushSubscription;


  
  constructor(private authService: NbAuthService,private https: ApiService, private router: Router, private swPush: SwPush) {
    
    Object.assign(this.datos);
    this.checkSuscription();
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.nombreCompleto = this.user.data.nombre+" "+this.user.data.apellidos;
        this.localizadorCompleto = "Localizador: "+this.user.data.localizador;
        
      }
    });

  }

  ngOnInit() {

    localStorage.removeItem('excursiones_contratadas');
    var t = localStorage.getItem('auth_app_token');
    this.checked = JSON.parse(localStorage.getItem('notiPush'));
    this.token = t;
    // Esto para recargar la pagina
    // this.router.navigateByUrl("pages/perfil");

  }

  

  checkSuscription(): boolean{
    
    if(this.swPush.isEnabled){
      this.swPush.subscription.subscribe((res) =>{
        console.log(res);
        this.pushSubscription = res;  
        if(this.pushSubscription === null){
          this.checked = false;
          localStorage.removeItem('notiPush');
        }else{
          this.checked = true;
          localStorage.setItem('notiPush', JSON.stringify(this.checked))
        }
      })

      return this.checked;

    }
  }

  changePushSubscription(){

    if(this.swPush.isEnabled){

      if(this.pushSubscription == null){
        //Añadir la suscripción push
        console.log("Falso 2")
        this.pushSubscribe();
        this.checked = true;
      }else{ 
        //Borrar la suscripción push y desuscribirse
        console.log("Verdadero 2")
        this.https.postRespuesta({token: this.token, suscripcion: this.pushSubscription}, "https://api.cisnea.com/deleteSubscription.php").subscribe();
        this.pushSubscription.unsubscribe().then((res) => {
          this.checkSuscription();
        });

  
      }


      return this.checked
    }

  }

  pushSubscribe(){

      this.swPush
        .requestSubscription({
          serverPublicKey: environment.VAPID_KEY,
        })
        .then((sub) => {
          this.https
            .addSubscription(
              sub,
              localStorage.getItem('auth_app_token'),
              'https://api.cisnea.com/push_subscription.php'
            )
            .subscribe();
            this.swPush.subscription.subscribe((res) => {
              this.pushSubscription = res;
  
            })
  
        })
        .catch((err) => {
        });
    
  
}




 

}

