import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BienvenidaComponent } from './componentes/bienvenida/bienvenida.component';


const routes: Routes = [
      // { path: '**', component:  }
    
    {
      path: '',
      loadChildren: () => import('./componentes/auth/auth.module').then(m => m.AuthModule),
      runGuardsAndResolvers: 'always'
      // ahora se activa incluso si intenta navegar a la ruta actual.
    },
     
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
