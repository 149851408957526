import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../../../services/conexionApi.service';
import { Peticion } from '../../../services/peticion';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-michat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './michat.component.html',
  styleUrls: ['./michat.component.scss']
})
export class MichatComponent implements OnInit {
  @Input() noMessagesPlaceholder: string = 'No hay mensajes todavía.';

  messages:any = [];
   nombre;
   pax:any;
   user:any;
   soyGuia = false;
   public load: boolean;

     // con esto sacamos del la url el tipo de char para utilizar el mismo componente
  parametro = this.route.snapshot.url.toString();
  tipoChat = this.parametro.split(",");

  t = localStorage.getItem('auth_app_token');
   


  sendMessage(event: any, userName: string, avatar: string, reply: boolean) {
    
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });


    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: reply,
      type: files.length ? 'file' : 'text',
      files: files,
      user: {
        name: userName,
        avatar: avatar,
      },
    });
    // aquiii llamo para enviar mensajes 
    this.enviarDatos(event.message);
    this.notificarPush({ token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] });
    
  }


  constructor(private _location: Location,private https: ApiService,private cdr: ChangeDetectorRef, private route: ActivatedRoute,private zone: NgZone,protected service: NbAuthService,private router: Router) {
 
    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      this.parametro = this.route.snapshot.url.toString();
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        
      }
    });

   }



  ngOnInit() {
    this.obtenerDatos({ token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] });
   
      setInterval(() => {
        this.isNuevosMensajes( { token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] } );
      }, 5000); 
    
    // this.enviarDatos();

    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
  }, 500);
  }

  // ngAfterViewInit(): void {
  //   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //   //Add 'implements AfterViewInit' to the class.
  //   this.obtenerDatos({ token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] });
  //   this.obtenerDatos({ token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] });
    
  // }


  goBack(){
    this.load = false;
    this._location.back();
  }

  isNuevosMensajes(body: Peticion){
    
    this.https.postRespuesta(body, "https://api.cisnea.com/nuevosMensajes.php").subscribe(
      info => {
        // si devuelve true es que hay nuevos mensajes por lo que hay que recargar el array de mensajes
        if (info == true) {
          this.obtenerDatos({ token: this.t, tipo: this.tipoChat[this.tipoChat.length - 1] });
        }
        
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/consultaMensajes.php").subscribe(
      info => {
        //  alert(this.tipoChat[this.tipoChat.length - 1]);
        this.messages = info;
        
        var cabecera= this.tipoChat[1].split("-");
       
        if(cabecera[0]=="g"){
          this.nombre = cabecera[cabecera.length -1];
        }else{

          switch (this.tipoChat[this.tipoChat.length - 1]) {
            case "1":
              this.nombre = "Coordinador";
              break;
  
              case "2":
              this.nombre = "Dudas y preguntas";
              break;
  
              case "3":
              this.nombre = "Guía";
              break;
  
            default:
              break;
          }

        }

        this.cdr.markForCheck ();
      

      },
      err => {
        console.log(err);
      }
    );

    

    // this.MyProp.click();

  }

  enviarDatos(mensaje){
    var t = localStorage.getItem('auth_app_token');
    
    this.https.postRespuesta({token:t, mensaje: mensaje, tipo: this.tipoChat[this.tipoChat.length - 1] }, "https://api.cisnea.com/insertaMensajes.php").subscribe(
      info => {
        alert("bjhbjbhjbhjbhbhjbhjbhhjhhjb");
        
        alert("fdfdsffsd");
        this.cdr.markForCheck ();

      },
      err => {
        console.log(err);
      }
    );

    

    
  }

  notificarPush(body: Peticion){

    this.https.postRespuesta(body, "https://api.cisnea.com/mensajesPush.php").subscribe(
      info => {
        this.cdr.markForCheck ();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );

  }


  
}
