import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';
import { Location } from '@angular/common';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})


export class DocumentacionComponent implements OnInit {
  single = [];
  user:any;
  pax = "";
  urlPdf = "/assets/bono.pdf";
  canIShow = false;
  public load: boolean;

  @ViewChild('closebutton') closebutton;


  constructor(private _location: Location,private zone: NgZone,protected service: NbAuthService,private https: ApiService,private cdr: ChangeDetectorRef, private router: Router, private location: Location ) {
    Object.assign(this.single);
    this.load = false;

    location.subscribe((value) => {
      this.closebutton.nativeElement.click();
     

  });

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if(this.pax == "guia" || this.pax == "hotel" || this.pax == "cliente_hotel"){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }
      }
    });
  }

  t = localStorage.getItem('auth_app_token');
  
  ngOnInit() { 
    this.getDoc({token:this.t});
    // this.reloadPage();
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }


 
  
  cerrarModal(){

    this.closebutton.nativeElement.click();
  }

  getDoc(body: Peticion) {
    this.https.postRespuesta(body,"https://api.cisnea.com/documentacion.php").subscribe(
      info => {
        this.single = info;
        this.cdr.markForCheck ();
        this.load = true;

      },
      err => {}
    );
  }
  establecerUrlPdf(url:string){
    console.log(url);
    this.urlPdf = url;
  }


  reloadPage() { 
    let isRedirected = sessionStorage.getItem('isRedirected');
    if (isRedirected!='true') {
      sessionStorage.setItem('isRedirected', 'true');
      this.zone.runOutsideAngular(() => {
        location.reload();
    });
    }
  }
}

