<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>

<div class="row containerPeticion" *ngIf="tieneNotif === false;">
  <div class="col ">
    <div id="pedirSuscripcion">
      <p class="notifP">¿Quieres que te enviemos notificaciones? </p> <p (click)="pushSubscribe()"><span> Toca aquí. </span></p> 
    </div>
  </div>
</div>

<section class="page-section mt-1 pt-1" id="services">
  <div class="container-fluid">

      <div [ngSwitch]="quienSoy" >
 
        <div class="row my-flex-card" *ngSwitchCase="'guia'"> <!-- SECCION DE GUIA -->

          <div class="col-xl-6 col-md-12 pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/informeventas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Informe de ventas</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;"  class="fas fa-hand-holding-usd text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/puntosinteres']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Lugares de interés</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-map-marker-alt fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/entradas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Entradas y espectáculos</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                      confirmation_number
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/excursiones']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Excursiones</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons text-info ">
                      directions_bus
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/rentacar']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Alquila y muévete</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-route fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/whispers']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Conexión Guias</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;"  class="fas fa-headset text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/qr']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Lector QR</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-qrcode fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="info.length > 0">
            <div class="col-xl-6 col-md-12  pr-0 pl-0 ">
              <div class="card shadow border-danger btn " [routerLink]="['/pages/encuestas']">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col  d-flex justify-content-start align-items-center">
                      <h6 class="text-info mt-2 ">Tu opinión nos interesa</h6>
                    </div>
                    <div class="col-auto">
                      <span style="font-size: 35px !important;" class="material-icons mr-1 text-info">
                        checklist_rtl
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          
          

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/chats']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Contáctanos</h6>
                  </div>
                  <div class="col-auto">
                    <!-- <span style="font-size: 35px !important;" class="material-icons text-info ">
                      not_listed_location
                      </span> -->
                      <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                        phone_in_talk
                        </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


         


        </div>

        <div class="row  my-flex-card" *ngSwitchCase="'hotel'"> <!-- SECCION DE RECEPCION HOTEL -->
        
          <!-- <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/rentacar']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Alquila y muévete</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-route fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/puntosinteres']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Lugares de interés</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-map-marker-alt fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/entradas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Entradas y espectáculos</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                      confirmation_number
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/excursiones']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Excursiones</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons text-info ">
                      directions_bus
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/informeventas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Informe de ventas</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;"  class="fas fa-hand-holding-usd text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row  my-flex-card" *ngSwitchCase="'cliente_hotel'"> <!-- SECCION DE CLIENTE HOTEL -->


          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/conocetuhotel']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Conoce tu hotel</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons text-info ">
                      directions_bus
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/puntosinteres']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Lugares de interés</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-map-marker-alt fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/entradas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Entradas y espectáculos</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                      confirmation_number
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/excursiones']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Excursiones</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons text-info ">
                      directions_bus
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/chats']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Contáctanos</h6>
                  </div>
                  <div class="col-auto">
                     <!-- <span style="font-size: 35px !important;" class="material-icons text-info ">
                      not_listed_location
                      </span> -->
                      <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                        phone_in_talk
                        </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/chats/michat/4']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Servicio de habitaciones</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-concierge-bell fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row  my-flex-card" *ngSwitchDefault> <!-- SECCION DE CLIENTE OPERADOR -->


          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/documentacion']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Documentación</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-atlas fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/puntosinteres']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Lugares de interés</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-map-marker-alt fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/entradas']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Entradas y espectáculos</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                      confirmation_number
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/lugares/excursiones']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Excursiones</h6>
                  </div>
                  <div class="col-auto">
                    <span style="font-size: 35px !important;" class="material-icons text-info ">
                      directions_bus
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/rentacar']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Alquila y muévete</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;" class="icon fas fa-route fa-inverse text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/whispers']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Conexión Guias</h6>
                  </div>
                  <div class="col-auto">
                    <i style="font-size: 35px !important;"  class="fas fa-headset text-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="info.length > 0">
            <div class="col-xl-6 col-md-12  pr-0 pl-0 ">
              <div class="card shadow border-danger btn " [routerLink]="['/pages/encuestas']">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col  d-flex justify-content-start align-items-center">
                      <h6 class="text-info mt-2 ">Tu opinión nos interesa</h6>
                    </div>
                    <div class="col-auto">
                      <span style="font-size: 35px !important;" class="material-icons mr-1 text-info">
                        checklist_rtl
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          

          

          <div class="col-xl-6 col-md-12  pr-0 pl-0">
            <div class="card shadow border-danger btn " [routerLink]="['/pages/chats']">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col  d-flex justify-content-start align-items-center">
                    <h6 class="text-info mt-2 ">Contáctanos</h6>
                  </div>
                  <div class="col-auto">
                     <!-- <span style="font-size: 35px !important;" class="material-icons text-info ">
                      not_listed_location
                      </span> -->
                      <span style="font-size: 35px !important;" class="material-icons-outlined text-info">
                        phone_in_talk
                        </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          

        </div>


      </div>


  </div>
</section>