<a class="history-back" (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<section class="page-section" id="services" >

    <div class="container-fluid">
        <div class="row">
            <div class="col text-center mb-4">
                <img src="../../../../../assets/img/finalizaCompra.svg" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col text-center mb-4 " >
                <h2 style="color: white !important;">¡Compra finalizada!</h2>
                <p style="color: white !important;" >En breve recibirá su ticket en la pestaña de documentación. <br> <br> Gracias.</p>
            </div>
        </div>
        <div class="row ">
            <div class="col text-center">
                <button class="btn btnVolver w-75" [routerLink]="['/pages/inicio']" style="color: white !important;"> Volver a inicio </button>
            </div>
        </div>
    </div>

</section>