import { Component, OnInit,ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dudas',
  templateUrl: './dudas.component.html',
  styleUrls: ['./dudas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
 

export class DudasComponent implements OnInit {

  user:any;
  pax:any;

  constructor(private _location: Location,private zone: NgZone,protected service: NbAuthService,private router: Router  ) {

    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if( this.pax == "hotel"){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }
      }
    });

  }

  ngOnInit() {  
  }

  goBack(){
    this._location.back();
  }

}