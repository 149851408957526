import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {
   nombre;
   pax:any;
   user:any;
   resolvemos = false;
   contactaguia = false;
   soyGuia = false;
   atulado = false;
   public load: boolean;

   chats:any = [];

  constructor(private _location: Location,private https: ApiService,private cdr: ChangeDetectorRef, private route: ActivatedRoute,private zone: NgZone,protected service: NbAuthService,private router: Router) {
    this.load = false;
    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if(this.pax == "guia"){
          this.soyGuia = true;
        }
        if(   this.pax == "hotel" ){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }else{
            switch (this.pax) {
                case "cliente_hotel":
                    this.resolvemos = true;
                    this.atulado = true;
                    this.load = true;
                    break;
                default:
                    this.resolvemos = true;
                    this.contactaguia = true;
                    this.atulado = true;
                    this.load = true;
                    break;
            }
        }
      }
    });

   }


  ngOnInit() {
    var t = localStorage.getItem('auth_app_token');
    if(this.soyGuia == true){
      //llamo a metodo que consulta tabla de guia con todos los chats

      this.obtenerDatosGuia({ token: t, });


    }
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
  }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  obtenerDatosGuia(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/consultaChats.php").subscribe(
      info => {

        this.chats = info;

        this.cdr.markForCheck ();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }





  
}
