
export class Peticion {
    public tipoTest?:String;
    public paxIncluidos?:any;
    public token? : any;
    public comida? : any;
    public confirmacion? : any;
    public nombre? : any;
    public pass? : any;
    public tiempo ? :any;
    public accion? : any;
    public fecha?:any;
    public excursion?: any;
    public tipo?: any;
    public insercion?:any;
    public id?:any;
    public exc?:any;
    public email?:any;
    public fullname?:any;
    public localizador?:any;
    public pago?:any;
    public canal?:any;
    public uid?:any;
}
