import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input,Renderer2, NgZone  } from '@angular/core';
import { Location } from '@angular/common';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import {formatDate} from '@angular/common';



import { NbCalendarRange, NbDateService } from '@nebular/theme';
import { AnySourceData } from 'mapbox-gl';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-contratacion',
  templateUrl: './contratacion.component.html',
  styleUrls: ['./contratacion.component.scss']
})


export class ContratacionComponent implements OnInit {

  checked = false;
  spto = false;
  toggleNgModel = false;
  clickeable = false;
  peticion = new Peticion();
  datos:any = [];
  servicios:any = [];
  personas:any = [];
  fechas:any = [];
  paxSeleccionados:any = [];
  idPaxSeleccionados:any = [];
  public load: boolean;
  fechasDisponibles:any;
  hoyDisponible:any;
  hoyCuantasPlazas:any;
  idExcursion:any;

  // con dias habilitados 
  date: Date;
  min: Date;
  max: Date;

  // DATOS NUEVO USER
  nomNewUser;
  apellidosNewUser;
  dniNewUser;
  edadNewUser;
  habitacionNewUser;
  localizador;

  //datos tipo lugar
  tipo = this.route.snapshot.paramMap.get('tipo');
  
  inicialTipo = "E";
  titulo = "Excursión";
  hayError = false;
  user: any;
  quienSoy: string;

  constructor(private zone: NgZone,private authService: NbAuthService,private renderer: Renderer2,private activatedRoute:ActivatedRoute,private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router, private route: ActivatedRoute,protected dateService: NbDateService<Date>) {
    this.date = this.dateService.today();
    this.min = this.dateService.addDay(this.date, -7);
    this.max = this.dateService.addDay(this.date, 3);
    if (this.tipo=="excursiones") {
      this.titulo = "Excursión";
      this.inicialTipo = "E";
    } else {
      this.titulo = "Entrada"
      this.inicialTipo= "EYE";
    }
    this.load = false;

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax;       
        if(this.quienSoy == "hotel" || this.quienSoy == "guia"){
          this.localizador = this.route.snapshot.paramMap.get('localizador');
        }
      }
    }); 

    
  }

  get monthStart(): Date {
    return this.dateService.getMonthStart(new Date());
  }

  get monthEnd(): Date {
    return this.dateService.getMonthEnd(new Date());
  }

  selected: { startDate: Date, endDate: Date };
  origen: Text;
  destino: Text;
  habPer: Text;

  id = this.route.snapshot.paramMap.get('numero');
  t = localStorage.getItem('auth_app_token');
  ngOnInit() {
    console.log(this.inicialTipo);
    if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
      this.obtenerDatos({ token: this.t,tipo:this.inicialTipo, localizador:this.localizador });
    }else{
      this.obtenerDatos({ token: this.t,tipo:this.inicialTipo});
    }
    
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);

     setInterval(() => {
      var anio = new Date(this.date).getFullYear();
      var mes = new Date(this.date).getMonth();
      var dia = new Date(this.date).getDate();
      console.log("exc fecha "+anio+"-"+mes+"-"+dia);
      if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
        this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:anio+"-"+mes+"-"+dia,localizador:this.localizador },"confecha");
      }else{
        this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:anio+"-"+mes+"-"+dia },"confecha");
      }
      
     }, 60000);

  }

  hayPlazas(body:Peticion, tipo){
    this.https.postRespuesta(body, 'https://api.cisnea.com/consultarPlazas.php').subscribe(
      info => {
        if(tipo == "sinfecha"){
          this.fechasDisponibles = info;
          console.log(this.fechasDisponibles);
          //this.generarRango();

        }else{
          this.hoyDisponible = info.split("-")[0];
          this.hoyCuantasPlazas = info.split("-")[1];
          if (this.hoyCuantasPlazas>this.hoyDisponible) {
            alert("Solo quedan "+this.hoyDisponible+" plazas disponibles.");
            
          }
        }
        this.cdr.markForCheck ();
      },
      err => {
        console.log("No hay plazas disponibles");
         
        
      }
    );
  }

  toggle(checked: boolean) {
    this.spto = checked;
  }

  goBack() {
    this.load = false;
    this._location.back();
  }

  generarRango(){
    var fechaI = new Date();
    fechaI.setDate(fechaI.getDate() + 1);
    var fechaF = new Date(this.fechas[1]);

    this.date = this.dateService.today();
    this.min = fechaI;
    this.max = fechaF;

    //aqui controlar fechas una a una y si tiene plazas, pues ponemos disponibles solo esas fechas
    this.fechasDisponibles.forEach(element => {
      console.log(element.fecha+" plazas: "+element.plazas);
      
    });
  }

  isClick(){
    this.clickeable = true;
    this.cdr.markForCheck ();
  }

  addUser(){
    let peticion ;
    if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
      peticion = {token:this.t,nombre:this.nomNewUser, apellidos:this.apellidosNewUser,dni:this.dniNewUser,edad:this.edadNewUser,hab:this.habitacionNewUser, localizador:this.localizador};
    }else{
      peticion = {token:this.t,nombre:this.nomNewUser, apellidos:this.apellidosNewUser,dni:this.dniNewUser,edad:this.edadNewUser,hab:this.habitacionNewUser};
    }
    
    this.https.postRespuesta(peticion, 'https://api.cisnea.com/addUser.php').subscribe(
      info => {
        if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
          this.obtenerDatos({ token: this.t,tipo:this.inicialTipo, localizador:this.localizador });
        }else{
          this.obtenerDatos({ token: this.t,tipo:this.inicialTipo});
        }
        this.nomNewUser = "";
        this.dniNewUser = "";
        this.edadNewUser = "";
        this.apellidosNewUser = "";
        this.habitacionNewUser = "";
        this.clickeable = false;
      },
      err => {
        console.log(err);
      }
    );
  }

   
  comprobar(indice, idPerson){
    
    if(this.paxSeleccionados.indexOf(idPerson) == -1){
      this.paxSeleccionados.push(idPerson);
      this.idPaxSeleccionados.push(indice);
      this.checked = true;
    }else{
      var i = this.paxSeleccionados.indexOf( idPerson );
      this.paxSeleccionados.splice( i, 1 );
      this.idPaxSeleccionados.splice(i,1);
      this.checked = false;
    }
    return this.checked;
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, 'https://api.cisnea.com/excursiones.php').subscribe(
      info => {
        this.datos = info.excursiones[this.id];
        this.idExcursion = this.datos.id;
        if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
          this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:null,localizador:this.localizador },"sinfecha");
        }else{
          this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:null },"sinfecha");
        }
        
        this.fechas = info.excursiones[this.id].fechas.split(' -> ');
        this.personas = info.personas;
        console.log(this.datos);
        this.servicios = this.datos.servicios.split('-');
        this.cdr.markForCheck ();
        this.generarRango();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }
 
  enviarDatos() {
    let idpax = "";
    for (let index = 0; index < this.idPaxSeleccionados.length; index++) {
      if(index == (this.idPaxSeleccionados.length-1)){
        idpax+=this.idPaxSeleccionados[index].toString();
      }else{
        idpax+=this.idPaxSeleccionados[index].toString();
        idpax+=", ";
      }
      
      console.log(idpax); 
    }
    let f = formatDate(this.date, 'yyyy/MM/dd','en');

    let comidaContratada = 'N';
    if (this.spto==true) {
      comidaContratada = 'S';
    }

    var anio = new Date(this.date).getFullYear();
    var mes = new Date(this.date).getMonth();
    var dia = new Date(this.date).getDate();
    console.log(anio+"-"+mes+"-"+dia);
    let peticion : Peticion;
    if (this.quienSoy == "hotel" || this.quienSoy == "guia") {
      this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:anio+"-"+mes+"-"+dia,localizador:this.localizador },"confecha");
      peticion = {token:this.t, excursion:this.datos.id, fecha:f, paxIncluidos:idpax, comida:comidaContratada, localizador:this.localizador };
    }else{
      this.hayPlazas({ token: this.t,excursion:this.idExcursion,fecha:anio+"-"+mes+"-"+dia },"confecha");
      peticion = {token:this.t, excursion:this.datos.id, fecha:f, paxIncluidos:idpax, comida:comidaContratada};
    }
    
    if(idpax == ""){
      alert("Selecciona alguna persona para esta excursión");
    }else{//si hay seleccionado algun usuario
      this.https.postRespuesta(peticion, 'https://api.cisnea.com/contratacionExcursion.php').subscribe(
        info => {
          var exc_contrats = localStorage.getItem('excursiones_contratadas');
          if(exc_contrats == null){
            exc_contrats = "";
          }
          localStorage.setItem('excursiones_contratadas', exc_contrats+","+info);
          this.router.navigate(['resumen/'+info],{relativeTo: this.activatedRoute}); 
        },
        err => {
          //console.log(err); 
          if (this.hayError == false) {
            alert(err.error);
            this.hayError = true;
          }
          
        }
      );
    }
    
  }

}