import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss']
})
export class NotificacionComponent implements OnInit {

  public load: boolean;
  datos:any = [];
  notificacionesNuevas;

  constructor(private _location: Location, private https: ApiService,private cdr: ChangeDetectorRef,private route: ActivatedRoute) { 
    this.load = false;
  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
  }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }
  id = this.route.snapshot.paramMap.get('id');

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/notificaciones.php").subscribe(
      info => {
        this.datos = info[this.id];
        this.cdr.markForCheck ();
        console.log(this.datos);
        this.load = true;
        this.enviarDatos();
      },
      err => {
        console.log(err);
      }
    );
  }

  enviarDatos(){
    var t = localStorage.getItem('auth_app_token');//que recoja el id para poner a check 
    this.https.postRespuesta({token:t, confirmacion: "S",idNotificacion:this.datos.id }, "https://api.cisnea.com/notificacionCheck.php").subscribe(
      info => {
        this.isNotificacionNueva({token:t});
      },
      err => {
        console.log(err);
      }
    );

    
  } 
  
  isNotificacionNueva(body: Peticion){
    this.https.postRespuesta(body, "https://api.cisnea.com/nuevasNotificaciones.php").subscribe(
      info => {
        this.notificacionesNuevas = info;
        console.log(info);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

}
