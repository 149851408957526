<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
  </a>
  <div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Lugares de interés</h6>
  </div>
  <div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
  <section class="page-section mt-5 pt-1 mb-3" id="services">
      <div class="container-fluid">
  
    <div class="row" *ngFor="let item of datos; let i=index">
      <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
          <div class="card shadow py-2 btn" style="background-image:url({{item.img}}); width:100%" (click)="verPI(i);">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <div class="col d-flex justify-content-start align-items-end">
                  <p class="text-white font-weight-bold title">{{item.nombre | titlecase}}</p>
                </div>
            </div>  
          </div>
        </div>
  
     </div>

     <div class="row" *ngFor="let item of datosGuia; let in=index">
      <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
          <div class="card shadow py-2 btn" style="background-image:url({{item.img}}); width:100%" (click)="verGuia(in);">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <div class="col d-flex justify-content-start align-items-end">
                  <p class="text-white font-weight-bold title">{{item.nombre | titlecase}}</p>
                </div>
            </div> 
          </div>
        </div>
  
     </div>
  </div>
  </section>