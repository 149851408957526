<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Conexión con el guía</h6>
</div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>

<ng-container *ngIf="pax == 'guia' && callStatus == 'CONNECTED'; else elseTemplate">
  <div class="w-100 containerCall" >
    <div class="d-flex flex-column justify-content-center  speaker">

      <div class="row d-flex flex-column userListener">
        <div class="col">
          <div class="containerListener d-flex" *ngFor="let rmtUser of remoteUsersObj; index as i ">
            <i class="fas fa-user"></i>
            <div class="userWrap" >
              <p [ngStyle]="{'color': rmtUser.isSpeaking === true ? greenLight : 'black !important' }" >Usuario {{rmtUser.user.uid}}</p>
            </div>
            <div *ngIf="!rmtUser.micRemoteStatus">
              <i class="fas fa-microphone-slash" [ngStyle]="{'color': rmtUser.micRemoteStatus === false ? red : green}" ></i>
            </div>
            <div *ngIf="rmtUser.micRemoteStatus">
              <i class="fas fa-microphone" [ngStyle]="{'color': rmtUser.micRemoteStatus === true ? green : red}" ></i>
            </div>
          </div>
        </div>
      </div>


      <div class="callButtons d-flex justify-content-between">
          <div class="soundMute d-flex justify-content-center align-items-center" (click)="changeVolumeStatus()">
            <div *ngIf="!volumeStatus">
              <i   class="fas fa-volume-mute"></i>
            </div>
            <div *ngIf="volumeStatus">
              <i   class="fas fa-volume-up"></i>
            </div>
          </div>

          <div class="muteContainer d-flex justify-content-center align-items-center" (click)="changeMicStatus()" [ngStyle]="{'background-color': micStatus === false ? red : green}" >
            <div *ngIf="!micStatus">
              <i  class="fas fa-microphone-slash"></i>
            </div>
            <div *ngIf="micStatus">
              <i  class="fas fa-microphone"></i>
            </div>
          </div>
          <div class="salirContainer d-flex justify-content-center ">
            <div class="salirWrapper d-flex align-items-center flex-column" (click)="leaveCall()" routerLink="/pages/whispers">
              <i class="fas fa-phone-slash" ></i>
            </div>
          </div>

        </div>

    </div>

    
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="w-100 containerCall" *ngIf="callStatus == 'CONNECTED' ">
    <div class="d-flex flex-column justify-content-center  speaker">
      <div class="row">
        <div class="col" >
          <div class="guideSpeaker flex-column">
            <h5>Guía</h5>
            <div class="guideIcon d-flex flex-column justify-content-center align-items-center" *ngIf="!remoteUsersObj[0]; else guiaSpeakingTemplate">
              <i class="fas fa-user guideUser"></i>
            </div>
            <ng-template #guiaSpeakingTemplate>
              <div class="guideIcon d-flex flex-column justify-content-center align-items-center"[ngStyle]="{'border': remoteUsersObj[0].isSpeaking ? borderColor : 'none'}">
                <i class="fas fa-user guideUser"></i>
              </div>

            </ng-template>
            
            <h6 class="mt-2" *ngIf="remoteUsersObj[0]">{{remoteUsersObj[0].user.uid}}</h6>
          </div>


        </div>
      </div>
      <div class="row d-flex flex-column userListener">
        <div class="col">
          <div class="containerListener d-flex">
            <i class="fas fa-user"></i>
            <p [ngStyle]="{'color': localStatus === true ? greenLight : 'white'}">Yo</p>

            <div *ngIf="!micStatus">
              <i class="fas fa-microphone-slash" [ngStyle]="{'color': micStatus === false ? red : green}" ></i>
            </div>
            <div *ngIf="micStatus">
              <i class="fas fa-microphone" [ngStyle]="{'color': micStatus === true ? green : red}" ></i>
            </div>
          </div>
          <div class="containerListener d-flex" *ngFor="let rmtUser of remoteUsersObj; index as i ">
            <i class="fas fa-user"></i>
            <div class="userWrap">
              <p [ngStyle]="{'color': rmtUser.isSpeaking === true ? greenLight : 'white' }">Usuario {{rmtUser.user.uid}}</p>
            </div>
            <div *ngIf="!rmtUser.micRemoteStatus">
              <i class="fas fa-microphone-slash" [ngStyle]="{'color': rmtUser.micRemoteStatus === false ? red : green}" ></i>
            </div>
            <div *ngIf="rmtUser.micRemoteStatus">
              <i class="fas fa-microphone" [ngStyle]="{'color': rmtUser.micRemoteStatus === true ? green : red}" ></i>
            </div>
          </div>
        </div>
      </div>

        <div class="callButtons d-flex justify-content-between">
          <div class="soundMute d-flex justify-content-center align-items-center" (click)="changeVolumeStatus()">
            <div *ngIf="!volumeStatus">
              <i   class="fas fa-volume-mute"></i>
            </div>
            <div *ngIf="volumeStatus">
              <i   class="fas fa-volume-up"></i>
            </div>
          </div>

          <div class="muteContainer d-flex justify-content-center align-items-center" (click)="changeMicStatus()" [ngStyle]="{'background-color': micStatus === false ? red : green}" >
            <div *ngIf="!micStatus">
              <i  class="fas fa-microphone-slash"></i>
            </div>
            <div *ngIf="micStatus">
              <i  class="fas fa-microphone"></i>
            </div>
          </div>
          <div class="salirContainer d-flex justify-content-center ">
            <div class="salirWrapper d-flex align-items-center flex-column" (click)="leaveCall()" routerLink="/pages/whispers">
              <i class="fas fa-phone-slash" ></i>
            </div>
          </div>

        </div>

    </div>

    
  </div>


</ng-template>