import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { isIntersectionTypeNode } from 'typescript';

@Component({
  selector: 'app-resumenR',
  templateUrl: './resumenR.component.html',
  styleUrls: ['./resumenR.component.scss']
})


export class ResumenRComponent implements OnInit {

    id = this.route.snapshot.paramMap.get('numero');
    insercion = this.route.snapshot.paramMap.get('insercion');
    dia;
    total;
    personas ;
    cantidad ;
    cantidadPersonas ;
    peticion = new Peticion();
    datos:any = [];
    infoExcUsuarios:any;
    totales = 0;

    constructor(private _location: Location,  private route: ActivatedRoute,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router) {

    }

    exc_contrats;

    ngOnInit() { 
        this.dia = this.insercion.split("_")[1];
        this.total = this.insercion.split("_")[2];
        this.personas = this.insercion.split("_")[0];
        this.cantidad = this.personas.split(",");
        this.cantidadPersonas = this.cantidad.length;
        var t = localStorage.getItem('auth_app_token');
        this.exc_contrats = localStorage.getItem('excursiones_contratadas');
        // this.obtenerDatosExcursion({ token: t,insercion:this.insercion,excursion:this.exc_contrats });
        // this.obtenerDatos({ token: t });
    
    }

    goBack(){
        this._location.back();
    }

    obtenerDatosExcursion(body: Peticion){
        this.https.postRespuesta(body, "https://api.cisnea.com/datosExcComprada.php").subscribe(
            info => {
                this.infoExcUsuarios = info;
                this.cdr.markForCheck ();
                this.infoExcUsuarios.forEach(element => {
                this.totales += element.total;  
                });
            },
            err => {
                console.log(err);
            }
            );
    }

    obtenerDatos(body: Peticion) {
        this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
        info => {
            this.datos = info.excursiones;
            this.cdr.markForCheck ();
           
        },
        err => {
            console.log(err);
        }
        );
    }

    pagar(){
        this.router.navigate(['pagoR'],{relativeTo: this.route});
    }

    contratar(indice){
        this.router.navigate(['/pages/recomendaciones/id/'+indice]);
    }

}