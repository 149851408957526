import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';


@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
  
})
export class NotificacionesComponent implements OnInit{
  
  peticion = new Peticion();
  datos:any = [];

  // tipo = this.route.snapshot.paramMap.get('tipo');

  constructor (private cdr: ChangeDetectorRef, private https: ApiService, private router: Router, private route: ActivatedRoute){ 

  }

  ngOnInit(){ 
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    
  }
 
  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/notificaciones.php").subscribe(
      info => {
        this.datos = info;
        this.cdr.markForCheck ();
        console.log(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }


  
  verMensaje(id){
     this.router.navigate(['mensaje/'+id], {relativeTo: this.route});
  }
  
}