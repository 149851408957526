<a class='history-back ' [routerLink]="['/pages/inicio']" >
  <i class="fas fa-arrow-left text-primary " style="font-size: 25px!important;"></i>
</a>

<section class="page-section mt-1 pt-1 mb-3" id="services">
    <div class="container-fluid">
 
  <div class="row">


    <ng-container *ngFor="let item of datos; let i=index" >
      <div class="col-xl-4 col-md-6 mb-3 pl-0 pr-0" (click)="verMensaje(i)">
        <div class="card py-2 btn">
          <div class="card-body">
           
              <div class="row no-gutters align-items-center">
                <div class="col-auto mr-3">
                  <ng-container *ngIf="item.check == 'N'; else elseTemplate">
                    <i class="fas fa-check-circle text-danger" style="font-size: 25px!important;"></i> 
                  </ng-container>
                  <ng-template #elseTemplate>
                    <i class="fas fa-check-circle text-primary" style="font-size: 25px!important;"></i> 
                  </ng-template>
                  
                  
                </div>
                <div class="col d-flex justify-content-center align-items-start flex-column">
                  
                  <p class="text-dark text-left">{{item.texto}}</p>
                  <p class="text-light text-left">{{item.subtitulo}}</p>
                </div>
              </div>
         
            
          </div>
        </div>
      </div>
    </ng-container>
    

   </div>
</div>
</section>