<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Localizador</h6>
</div>

<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<section class="page-section mt-1 pt-1 mb-3" id="localizador">
    <div class="container-fluid">

  <div class="row mb-5 mt-5">
      
      <div class="form-control-group col-xl-6 col-md-12 pl-0 pr-0">
        <p>Localizador:</p>
        <input
                class="w-100 form-control"
                [(ngModel)]="localizador"
                #dni="ngModel"
                id="input-localizador"
                name="localizador"
                placeholder="Localizador">
      </div>

      <div class="col-xl-6 col-md-12 mt-3 pl-0 pr-0">
        <div class='d-flex justify-content-center align-items-center w-100'>
          <button type="button" class="btn btn-success text-center w-100" (click)="contratar()" >Continuar</button>
        </div>
      </div>
      

   </div>
</div>
</section>