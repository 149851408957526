<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>

<div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Encuestas</h6>
  </div>
  
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
    <div class="container-fluid">
        <ng-container *ngFor="let item of datos;index as i;">
            <div class="row" >
                <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0" (click)="hacerEncuesta(item.id)" >
                    <div class="card shadow-sm py-2 btn" >
                        <div class="card-body">
                            <div class="row no-gutters d-flex justify-content-start align-items-center">
                                <i class="fas fa-envelope-open-text text-primary mr-3"></i>
                                <p class="card-title text-primary">{{item.nombre}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
          
        </ng-container>
    

   </div>
</section>