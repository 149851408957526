<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta">
    <span>Resumen</span>
  </div>
<section class="page-section mt-4 pt-1 mb-3" id="services">
    <div class="container-fluid">
  
        <div class="row">
            <div class="col-xl-4 col-md-6 mb-3 d-flex justify-content-end align-items-center">
                <button type="button" class="btn btn-outline-light" (click)="goBack()">
                    <i  class="icon fas fa-edit fa-inverse text-info"></i>
                </button>
                
            </div>
        </div>

      <div class="row">
  
            <div class="col-xl-4 col-md-6 mb-3">
            
              <!-- <div class="card py-2 btn" style="background-image:url({{item.img}}); width:100%" (click)="verExcursion(item.id);" > -->

                <!-- <ng-container *ngFor="let item of infoExcUsuarios; let i=index"> -->
                    <div class="col-xl-4 col-md-6 mb-3 pl-0 pr-0">
                        <div class="card py-2 btn excursion" style="background-image:url('assets/img/entrada.jfif'); width:100%; background-repeat: no-repeat; background-size: cover;" >
                            <div class="card-body d-flex flex-column justify-content-center align-items-center p-1">
                                <div class="col d-flex flex-column justify-content-start align-items-start">
                                  <h6 class="text-white mb-0 mt-1">Añadido al carrito</h6>
                                  <h6 class='text-white font-weight-bold mb-2'>Día: {{dia}}</h6>
                                  <div class="d-flex justify-content-between align-items-center w-100">
                                      <p class='text-white  mb-0'>{{cantidadPersonas}} adultos  </p>
                                      <p class='text-white mb-0'>16.95€</p>
                                  </div>
                                <!-- <div class="d-flex justify-content-between align-items-center  w-100" *ngIf="item.comida != 'N'">
                                    <p class='text-white  mb-3' >Suplemento</p>
                                    <p class='text-white mb-3'>{{item.precio_suplemento}}€</p>
                                </div> -->
                                
                                </div>
                                <div class="col d-flex justify-content-between align-items-end pb-1">
                                <p class="text-white font-weight-bold mb-0 title">Sealife Benalmadena</p>
                                <p class="text-white font-weight-bold mb-0 title">{{total}}€</p>
                                </div> 
                            </div>
                        </div>
                    </div>
                <!-- </ng-container> -->

                
              
            </div>
          

            <div class="col-xl-3 col-md-6 mb-3" (click)="pagar()">
                <div class="bg-primary" >
                    <div class="bg-primary d-flex flex-row justify-content-center align-items-center pt-1">
                      <h6 class="text-light text-center">Total a pagar {{total}}€</h6>
                    </div>
                </div>
              </div>

            <!-- <p class="text-info fw-bold"> A continuación puedes seleccionar otra excursión si lo deseas</p>
        
            <ng-container *ngFor="let item of datos; let i=index">
                <div class="col-xl-4 col-md-6 mb-3">
                
                  <div class="card py-2 btn" style="background-image:url({{item.img}}); width:100%; background-repeat: no-repeat; background-size: cover;" (click)="contratar(i)">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center">
                        <div class="col d-flex flex-column justify-content-start align-items-end">
                          <p class="text-white desde">desde</p>
                          <p class='text-white font-weight-bold'>{{item.precio}}€</p>
                        </div>
                        <div class="col d-flex justify-content-start align-items-end pb-1">
                          <p class="text-white font-weight-bold title">{{item.nombre}}</p>
                        </div> 
                    </div>
                  </div>
                </div>
              </ng-container>
   -->
  
      </div>
    </div>
  </section>