<h1 id="title" class="title text-white">Registrarse</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh oh!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hurra!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="register()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group mb-1">
    <label class="label" for="input-localizador">Localizador:</label>
    <input nbInput
           [(ngModel)]="user.localizador"
           #localizador="ngModel"
           id="input-localizador"
           name="localizador"
           placeholder="Localizador"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="localizador.dirty ? (localizador.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.localizador.required')"
           [minlength]="getConfigValue('forms.validation.localizador.minLength')"
           [maxlength]="getConfigValue('forms.validation.localizador.maxLength')"
           [attr.aria-invalid]="localizador.invalid && localizador.touched ? true : null">
    <ng-container *ngIf="localizador.invalid && localizador.touched">
      <p class="caption status-danger" *ngIf="localizador.errors?.required">
        Se requiere el localizador!
      </p>
      <p class="caption status-danger" *ngIf="localizador.errors?.minlength || localizador.errors?.maxlength">
        El localizador debe contener entre
         {{getConfigValue('forms.validation.localizador.minLength')}}
        y {{getConfigValue('forms.validation.localizador.maxLength')}}
        caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group  mb-1">
    <label class="label" for="input-nombre">Nombre:</label>
    <input nbInput
           [(ngModel)]="user.nombre"
           #nombre="ngModel"
           id="input-nombre"
           name="nombre"
           placeholder="Nombre"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="nombre.dirty ? (nombre.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.nombre.required')"
           [minlength]="getConfigValue('forms.validation.nombre.minLength')"
           [maxlength]="getConfigValue('forms.validation.nombre.maxLength')"
           [attr.aria-invalid]="nombre.invalid && nombre.touched ? true : null">
    <ng-container *ngIf="nombre.invalid && nombre.touched">
      <p class="caption status-danger" *ngIf="nombre.errors?.required">
        Se requiere el nombre!
      </p>
      <p class="caption status-danger" *ngIf="nombre.errors?.minlength || nombre.errors?.maxlength">
        El nombre debe contener entre 
         {{getConfigValue('forms.validation.nombre.minLength')}}
        y {{getConfigValue('forms.validation.nombre.maxLength')}}
        caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group  mb-1">
    <label class="label" for="input-apellidos">Apellidos:</label>
    <input nbInput
           [(ngModel)]="user.apellidos"
           #apellidos="ngModel"
           id="input-apellidos"
           name="apellidos"
           placeholder="Apellidos"
           fullWidth
           fieldSize="large"
           [status]="apellidos.dirty ? (apellidos.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.apellidos.required')"
           [attr.aria-invalid]="apellidos.invalid && apellidos.touched ? true : null">
    <ng-container *ngIf="apellidos.invalid && apellidos.touched">
      <p class="caption status-danger" *ngIf="apellidos.errors?.required">
        Se requieren los apellidos!
      </p>
      <p class="caption status-danger" *ngIf="apellidos.errors?.pattern">
        Los apellidos deben tener entre 
        {{getConfigValue('forms.validation.apellidos.minLength')}}
       y {{getConfigValue('forms.validation.apellidos.maxLength')}}
       caracteres en total
      </p>
    </ng-container>
  </div>

  <div class="form-control-group  mb-1">
    <label class="label" for="input-dni">DNI:</label>
    <input nbInput
           [(ngModel)]="user.dni"
           #dni="ngModel"
           id="input-dni"
           name="dni"
           pattern="^([a-zA-Z]|\d){1}\d{7}[a-zA-Z]$"
           placeholder="DNI"
           fullWidth
           fieldSize="large"
           [status]="dni.dirty ? (dni.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.dni.required')"
           [attr.aria-invalid]="dni.invalid && dni.touched ? true : null">
    <ng-container *ngIf="dni.invalid && dni.touched">
      <p class="caption status-danger" *ngIf="dni.errors?.required">
        Se requiere el dni!
      </p>
      <p class="caption status-danger" *ngIf="dni.errors?.pattern">
        El DNI deben tener 
        {{getConfigValue('forms.validation.dni.maxLength')}}
       caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group  mb-1">
    <label class="label" for="input-edad">Edad:</label>
    <input nbInput
           [(ngModel)]="user.edad"
           #edad="ngModel"
           type="number"
           id="input-edad"
           name="edad"
           placeholder="Edad"
           fullWidth
           fieldSize="large"
           [status]="edad.dirty ? (edad.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.edad.required')"
           [minlength]="getConfigValue('forms.validation.edad.minLength')"
           [maxlength]="getConfigValue('forms.validation.edad.maxLength')"
           [attr.aria-invalid]="edad.invalid && edad.touched ? true : null">
    <ng-container *ngIf="edad.invalid && edad.touched">
      <p class="caption status-danger" *ngIf="edad.errors?.required">
        ¡Se requiere edad!
      </p>
      <p class="caption status-danger" *ngIf="edad.errors?.minlength || edad.errors?.maxlength">
        La edad debe ser inferior a 130 años
      </p>
    </ng-container>
  </div>

  <div class="form-control-group  mb-1">
    <label class="label" for="input-habitacion">Habitación:</label>
    <input nbInput
           [(ngModel)]="user.habitacion"
           #habitacion="ngModel"
           id="input-habitacion"
           name="habitacion"
           placeholder="Habitacion"
           fullWidth
           fieldSize="large"
           [status]="habitacion.dirty ? (habitacion.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.habitacion.required')"
           [minlength]="getConfigValue('forms.validation.habitacion.minLength')"
           [maxlength]="getConfigValue('forms.validation.habitacion.maxLength')"
           [attr.aria-invalid]="habitacion.invalid && habitacion.touched ? true : null">
    <ng-container *ngIf="habitacion.invalid && habitacion.touched">
      <p class="caption status-danger" *ngIf="habitacion.errors?.required">
        ¡Se requiere la habitación!
      </p>
      <p class="caption status-danger" *ngIf="habitacion.errors?.minlength || habitacion.errors?.maxlength">
        La habitación debe contener
         {{ getConfigValue('forms.validation.habitacion.minLength') }}
        a {{ getConfigValue('forms.validation.habitacion.maxLength') }}
        caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group" *ngIf="getConfigValue('forms.register.terms')">
    <nb-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
      De acuerdo con <a href="https://cisnea.com/politicapv/" target="_blank"><strong>Términos y condiciones</strong></a>
    </nb-checkbox>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    Registrarse
  </button>
</form>

<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  o entra con:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section>

<section class="another-action" aria-label="Sign in">
¿Ya tienes una cuenta? <a class="text-link" routerLink="../login">Iniciar sesión</a>
</section>
