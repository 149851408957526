<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>

<div class="text-center contenedor-ruta align-items-center">
    <h6 class="text-primary mt-2 ">Encuesta</h6>
  </div>
  
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
    <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
<section class="page-section mt-5 pt-1 mb-3" id="services">
    <div class="container-fluid">
        <ng-container *ngFor="let item of datos;index as i;">
            <div class="row" >
                <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0" >
                    <div class="card py-2 btn" >
                        <div class="card-body">
                            <div class="row no-gutters d-flex flex-column justify-content-center align-items-start">
                                <p class="card-title text-primary">{{item.pregunta}}</p>
                                 <ng-container *ngIf="item.tipo.includes('Test'); else elseTemplate">
                                    <nb-radio-group name="{{i}}"  *ngFor="let check of item.checks; let idx = index" > 
                                        <nb-radio
                                        class="pr-2"
                                        [checked]="item.respuesta == (idx+1)"
                                        [value]="check"
                                        (click) = "comprobar(item.id,check,idx+1);">
                                        {{ check }}
                                        </nb-radio>
                                    </nb-radio-group>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <textarea nbInput fullWidth placeholder="Respuesta" (keypress)="keyPress(item.id,$event)" value="{{item.respuesta}}"  ></textarea>
                                </ng-template>       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
          
        </ng-container>

        <!-- <li *ngFor="let user of users; index as i; first as isFirst">
            {{i}}/{{users.length}}. {{user}} <span *ngIf="isFirst">default</span>
          </li> -->

    

        <div class='d-flex justify-content-center align-items-center mb-1 w-100'>
            <button type="button" class="btn enviar btn-success text-center w-100 mb-3" (click)="enviarDatos();" >Enviar</button>
        </div>
    

   </div>
</section>