import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/conexionApi.service';
import { Peticion } from '../../services/peticion';
import { Location } from '@angular/common';

@Component({
  selector: 'app-recomendaciones',
  templateUrl: './recomendaciones.component.html',
  styleUrls: ['./recomendaciones.component.scss']
})


export class RecomendacionesComponent implements OnInit {

  peticion = new Peticion();
  datos:any = [];
  
  constructor(private _location: Location, private https: ApiService, private cdr: ChangeDetectorRef,private router: Router) {}

  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    // this.obtenerDatos({ token: t });
  }

  goBack(){
    
    this._location.back();
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/excursiones.php").subscribe(
      info => {
        this.datos = info.excursiones;
        this.cdr.markForCheck ();
        console.log(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }

  verExcursion(id){

    this.router.navigateByUrl("/pages/recomendaciones/id/"+id);

  }

}