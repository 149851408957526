<a class='history-back' (click)="goBack()">
  <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<div class="text-center contenedor-ruta align-items-center">
  <h6 class="text-primary mt-2 ">Documentación</h6>
</div>
<div class="overlay d-flex justify-content-center align-items-center" *ngIf="!load">
  <div class="spinner-grow text-primary" style="width: 5rem; height: 5rem;" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</div>
<!-- <ng-container *ngIf="pax!='guia'; else elseTemplate"> -->
  <section class="page-section mt-1 pt-1 mb-3" id="services" >
    <div class="container-fluid">

  <div class="row mt-5"> 
    
    <ng-container *ngFor="let item of single">
      
      <div class="col-xl-6 col-md-12 mb-3 pl-0 pr-0">
        <div class="card shadow-sm py-2 btn ">
          <div class="card-body">
            <a role="button" class="link text-dark text-decoration-none" data-toggle="modal" href="#portfolioModal1" (mouseenter)="establecerUrlPdf(item.doc)">
              
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <!-- <p class="text-dark">{{item.tipo}}</p> -->
                <p>{{item.tipo}}</p>
              </div>
              <div class="col-auto rounded-circle bg-info">
                <ng-container *ngIf="item.tipo != 'Programa' or !item.tipo.includes('Ticket'); else elseTemplate">
                  <i class="far fa-file-alt text-white mt-0"></i>
                </ng-container>
                <ng-template #elseTemplate>
                  <i class="fas fa-paperclip text-white mt-0"></i>
                </ng-template>
                
                  
              </div>
            </div>
          </a>
          </div>
        </div>
      </div>



    </ng-container>


     <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-lg-12 p-0">
                          <div class="modal-body p-0">

                              <a class="m-1 text-rigth btn-cerrar" #closebutton data-dismiss="modal" type="button" ><i class="far fa-window-close text-danger text-rigth mr-3"></i></a>
                             
                              <ngx-extended-pdf-viewer 
                                  [src]="urlPdf" 
                                  useBrowserLocale="true" 
                                  height="95vh"
                                  [enablePrint] = "true"
                                  [showDownloadButton]="false" 
                                  [showRotateButton]="false" 
                                  [showOpenFileButton]="false" 
                                  [showZoomButtons]="false" 
                                  [showBorders]="false" 
                                  [showPrintButton]="false" 
                                  [backgroundColor]=""
                                  [enablePrint]="false" 
                                  [showBookmarkButton]="false" 
                                  [showSecondaryToolbarButton]="false" 
                                  [showPagingButtons]="false" 
                                  [sidebarVisible]="false" 
                                  [showSidebarOnLoad]=false>
                              </ngx-extended-pdf-viewer>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    

   </div>
</div>
</section> 
<!-- </ng-container>
<ng-template #elseTemplate>
  <h4>BIENVENIDO SEÑOR GUIA</h4>
</ng-template> -->

 
 