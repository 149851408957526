import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Peticion } from 'src/app/componentes/services/peticion';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-encuestas',
  templateUrl: './encuestas.component.html',
  styleUrls: ['./encuestas.component.scss']
})


export class EncuestasComponent implements OnInit {

  peticion = new Peticion(); 
  datos:any = [];
  user:any;
  pax:any;
  public load: boolean;


  constructor(private _location: Location,private https: ApiService, private cdr: ChangeDetectorRef,private router: Router,private zone: NgZone,protected service: NbAuthService,private route: ActivatedRoute) {
    this.load = false;
    this.service.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.pax = this.user.data.pax;
        if( this.pax == "hotel" || this.pax == "cliente_hotel"){
            this.zone.runOutsideAngular(() => {
              this.router.navigate(['pages/error']);
            });
        }
      }
    });


  }
  


  ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  

  goBack(){
    this.load = false;
    this._location.back();
  }

  

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/todasEncuestas.php").subscribe(
      info => {
        this.datos = info;
        console.log(this.datos);
        this.cdr.markForCheck ();
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }

  hacerEncuesta(id){
    this.router.navigate([id], {relativeTo: this.route});

  }
}
