<a class='history-back' (click)="goBack()">
    <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
</a>
<section class="page-section mb-3" id="services">
    <div class="container-fluid">
            <div class="row mb-5">
                <img src="assets/img/entrada.jfif" class='img-fluid rounded' />
                <!-- <img src='assets/img/bar ejemplo.jpg' class='img-fluid rounded' /> -->
                <div class="card w-100 info mb-5">
                    <div class="card-body mb-3 excursion-card">
                        <h5 class="title">Sealife Benalmádena</h5>
                        <p class="text">Explora el mundo acuático de la Selva Amazónica.
                            La selva amazónica es la selva tropical más grande del planeta y la más rica en especies.
                            Podrás observar de cerca la piraña roja, es un pez agresivo y un depredador muy veloz que nada en grandes bancos. Se alimenta de peces, semillas y frutos, pero, en grupo, pueden atacar animales tan grandes como un buey.
                            Visita también los peces del arrecife de coral y las estrellas marinas.
                            No nos olvidemos de varios de nuestros protagonistas.  Las nutrias que  todos los días a las 11:00, 13:00 y 15:00 hrs  les dan de comer.
                            Y del Reino del Caballito de mar.
                            Comen 3 veces al día. A las 15:30 nuestros acuaristas estarán ahí para  contarte ésta y otras muchas curiosidades.
                            Alimentación de tiburones martes, jueves, sábados y domingos a las 12:30h.</p>
                    </div>
                </div>
            <div class='comprar d-flex justify-content-center align-items-center'>
                <button type="button" class="btn btn-success text-center" [routerLink]="['contratacionR']"  >Comprar entrada</button>
            </div>
        </div>
    </div>
</section>