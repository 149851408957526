<div class="container">
    <div class="d-flex justify-content-start">
        <img src="assets/img/Logo-Cisnea-WEB-blanco.png" alt=""
            class="ml-auto mr-auto p-3 w-50 img-fluid text-center">
    </div>
    <p class="sub-title">Por favor, selecciona tu nombre</p>
    <div class="usuarios">
        
        <button *ngFor="let user of datos; index as i; first as isFirst" (click)="cargarUsuario(i+1)" nbButton fullWidth status="primary" size="large" >
            {{user.nombre}} {{user.apellidos}} {{token.pax}}
        </button> 

    </div>

    <hr>
    <section class="another-action d-flex flex-column justify-content-center align-items-center" aria-label="Register">
        <p class="text-center problemas">¿Tienes problemas para acceder?</p>
        <a class="text-link mr-auto ml-auto" routerLink="../contactanos">Contáctanos</a>
    </section>
</div>