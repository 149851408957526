import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class PoliticasComponent implements OnInit {


  constructor(private _location: Location, ) {}

  ngOnInit() {  
  }

  goBack(){
    this._location.back();
  }

}