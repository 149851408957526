import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/componentes/services/conexionApi.service';
import { Peticion } from 'src/app/componentes/services/peticion';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-localizador',
  templateUrl: './localizador.component.html',
  styleUrls: ['./localizador.component.scss']
})
export class LocalizadorComponent implements OnInit {

  peticion = new Peticion();
  ok:any;
  public load: boolean;
  localizador;
  user: any;
  quienSoy: string;
  t;
  
  constructor(private zone: NgZone,private authService: NbAuthService,private route: ActivatedRoute,private _location: Location, private https: ApiService, private cdr: ChangeDetectorRef,private router: Router) {
    this.load = false;
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        this.quienSoy = this.user.data.pax; 
        
        if( this.quienSoy != "hotel" && this.quienSoy != "guia"){
          this.zone.runOutsideAngular(() => {
            this.router.navigate(['pages/error']);
          });
        }
        
      }
    }); 
  }

  ngOnInit() { 
    this.t = localStorage.getItem('auth_app_token');
    setTimeout(() => {
      this.load = true;
      this.cdr.markForCheck ();
    }, 500);
  }

  goBack(){
    this.load = false;
    this._location.back();
  }

  contratar(){
    this.obtenerDatos({token:this.t,fullname:this.quienSoy,email:this.localizador});
    
  }

  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, 'https://api.cisnea.com/consultarLocalizador.php').subscribe(
      info => {
        this.ok = info;
        if((this.quienSoy == "hotel" || this.quienSoy == "guia")){
          this.router.navigate(['contratacion/'+this.ok], {relativeTo: this.route});
        }
        this.load = true;
      },
      err => {
        console.log(err);
      }
    );
  }
}
